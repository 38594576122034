import { createAsyncThunk } from '@reduxjs/toolkit'
import { GenAIAssistantThreadMessage, ListChatThreadGenAiMessagesByThreadIdQuery, ListChatThreadGenAiMessagesByThreadIdQueryVariables } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from 'src/globalUtils/graphql/queries';
import { RootState, AppDispatch } from '../../store'
import { graphQLClient } from '../../util'
import { updateFetchStatus } from '../robotConciergeSlice';

type Props = {
    threadId: string;
    orgId: string;
}
export const fetchChatThreadGenAiMessagesByThreadIdFetchKey = (threadId: string) => `fetchChatThreadGenAiMessagesByThreadId-${threadId}`
export const fetchChatThreadGenAiMessagesByThreadId = createAsyncThunk<
    {
        messages: GenAIAssistantThreadMessage[],
    } | undefined, // output
    Props, // input argument - projectName
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/listChatThreadGenAiMessagesByThreadId', async ({ threadId, orgId }: Props, { dispatch, getState }) => {
    const orgState = getState().robotConcierge.perOrganizationStates.find(o => o.organizationID === orgId)
    if (orgId == null || orgState == null) {
        return undefined;
    }

    const fetchStatus = orgState.fetchStatus[fetchChatThreadGenAiMessagesByThreadIdFetchKey(threadId)]
    if (fetchStatus === 'pending' || fetchStatus === 'fulfilled') {
        return undefined;
    }

    dispatch(updateFetchStatus({
        fetchKey: fetchChatThreadGenAiMessagesByThreadIdFetchKey(threadId),
        orgId,
        status: 'pending'
    }))

    let shouldFetch = true
    let nextToken = null
    let messages: GenAIAssistantThreadMessage[] = []

    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        const variables: ListChatThreadGenAiMessagesByThreadIdQueryVariables = {
            threadId,
            nextToken
        }
        // const user = await Auth.currentAuthenticatedUser() as CognitoUser
        const response: GraphQLResult<ListChatThreadGenAiMessagesByThreadIdQuery> = await graphQLClient.graphql<GraphQLQuery<ListChatThreadGenAiMessagesByThreadIdQuery>>({
            query: queries.listChatThreadGenAiMessagesByThreadId,
            variables
        });
        nextToken = response.data?.listChatThreadGenAiMessagesByThreadId?.nextToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        messages = messages.concat(response.data!!.listChatThreadGenAiMessagesByThreadId!!.genAiMessages)
    }
    /* eslint-enable no-await-in-loop */

    return {
        messages
    }
})

export default fetchChatThreadGenAiMessagesByThreadId