import type { ButtonBaseProps } from '@mui/material/ButtonBase';

import { useState, useCallback, useEffect } from 'react';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';

import { Label, LabelColor } from 'src/components/label';
import { Iconify } from 'src/components/iconify';
import { usePopoverV2, CustomPopoverV2 } from 'src/components/custom-popover-v2';
import { OrgRole } from "src/globalUtils/API";
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import WorkspacesRoundedIcon from '@mui/icons-material/WorkspacesRounded';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { updateSelectedOrganization } from 'src/store/authentication/authenticationSlice';
import { useCurrentSelectedOrganization } from 'src/store/authentication/selectedOrganizationHook';

// ----------------------------------------------------------------------

export type OrganizationsPopoverPropsData = {
  id: string;
  name: string;
  organizationRole: OrgRole | undefined;
  userJoinOrgDateTime: string | undefined;
}
export type OrganizationsSelectionPopoverProps = ButtonBaseProps & {
  data?: OrganizationsPopoverPropsData[];
};

function getLabelColor(isSuperUserSpecial: boolean, role: OrgRole | undefined): LabelColor {
  if (isSuperUserSpecial && role === OrgRole.OWNER) {
    return 'error'
  }
  return role === OrgRole.USER || role === undefined ? 'default' : 'info'
}

export function OrganizationsSelectionPopover({ data = [], sx, ...other }: OrganizationsSelectionPopoverProps) {
  const popover = usePopoverV2();
  const loggedInUser = useSelector((state: RootState) => state.authentication.loggedInUser!!)

  const isSuperUserSpecial = loggedInUser.userName === 'youyang.zhang@lyza-sg.com' || loggedInUser.userName === "arlenezhang"

  const mediaQuery = 'md';

  const [workspace, setWorkspace] = useState(data[0]);

  let labelColor: LabelColor = 'default'
  if (isSuperUserSpecial) {
    labelColor = 'error'
  } else {
    labelColor = getLabelColor(isSuperUserSpecial, workspace?.organizationRole)
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateSelectedOrganization({ orgId: workspace.id }))
    popover.onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, workspace])

  function rightSideLabel(role: OrgRole | undefined) {
    switch (role) {
      case OrgRole.ADMIN:
        return 'Admin'
      case OrgRole.OWNER:
        return 'Owner'
      case OrgRole.STUDENT:
        return 'Student'
      case OrgRole.TEACHER_FULL_TIME:
        return 'Teacher'
      case OrgRole.TEACHER_PART_TIME:
        return 'Teacher (PT)'
      case OrgRole.USER:
        return 'User'
      case undefined:
        return 'Free'

      default:
        // This should never be reached if all cases are covered
        const exhaustiveCheck: never = role;
        throw new Error(`Unhandled case: ${exhaustiveCheck}`);
    }
  }

  return (
    <>
      <ButtonBase
        disableRipple
        disabled={data.length <= 1}
        onClick={popover.onOpen}
        sx={{
          py: 0.5,
          gap: { xs: 0.5, [mediaQuery]: 1 },
          ...sx,
        }}
        {...other}
      >
        {workspace.organizationRole === undefined ? <WorkspacesRoundedIcon sx={{ width: 20, height: 20 }} /> : <CorporateFareRoundedIcon sx={{ width: 20, height: 20 }} />}

        <Box
          component="span"
          sx={{
            typography: 'subtitle2',
            display: { xs: 'none', [mediaQuery]: 'inline-flex' },
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 200
          }}
        >
          {workspace.name}
        </Box>

        <Label
          color={labelColor}
          sx={{
            height: 22,
            display: { xs: 'none', [mediaQuery]: 'inline-flex' },
          }}
        >
          {isSuperUserSpecial ? 'boss' : rightSideLabel(workspace.organizationRole)}
        </Label>


        {data.length >= 2 && <Iconify width={16} icon="carbon:chevron-sort" sx={{ color: 'text.disabled' }} />}
      </ButtonBase>

      <CustomPopoverV2
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={popover.onClose}
        slotProps={{ arrow: { placement: 'top-left' } }}
      >
        <MenuList >
          {data.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              selected={option.id === workspace?.id}
              onClick={(e) => setWorkspace(option)}
              sx={{ height: 48 }}
            >
              {option.organizationRole === undefined ? <WorkspacesRoundedIcon sx={{ width: 20, height: 20 }} /> : <CorporateFareRoundedIcon sx={{ width: 20, height: 20 }} />}

              <Box component="span" sx={{ flexGrow: 1 }}>
                {option.name}
              </Box>

              <Label
                color={getLabelColor(isSuperUserSpecial, option.organizationRole)}
              >
                {isSuperUserSpecial && option.organizationRole === OrgRole.OWNER ? 'boss' : rightSideLabel(option.organizationRole)}
              </Label>
            </MenuItem>
          ))}
        </MenuList>
      </CustomPopoverV2>
    </>
  );
}
