import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReactNode } from 'react';
import { NavigationTabValue } from 'src/routes/paths';

interface NavigationSliceState {
    horizontalNavTabs: NavigationTabValue[];
    activeTab?: NavigationTabValue;
}

const initialState: NavigationSliceState = {
    horizontalNavTabs: []
}

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        updateTopHorizontalNavigationTabs: (state, action: PayloadAction<NavigationTabValue[]>) => {
            state.horizontalNavTabs = action.payload
        },
        updateActiveNavigationTab: (state, action: PayloadAction<NavigationTabValue | undefined>) => {
            state.activeTab = action.payload
        },
    },
    extraReducers(builder) {

    }
})

export const { updateTopHorizontalNavigationTabs, updateActiveNavigationTab } = navigationSlice.actions

export default navigationSlice.reducer