// ----------------------------------------------------------------------

import { RobotCustomAction, RobotCustomActionMatchResult, Role } from "src/globalUtils/API";

export type IChatAttachment = {
  name: string;
  size: number;
  type: string;
  path: string;
  preview: string;
  createdAt: Date;
  modifiedAt: Date;
};

export type IChatMessage = IChatMessageGenAIAssistantThreadMessage | IChatMessageUserWhatsAppMessage;

export const ANONYMOUS_GUEST = 'anonymous_guest'
export type AnonymousGuest = 'anonymous_guest'

type IChatMessageShared = {
  id: string; // used as react item rendering key
  createdAt: string; // ISO date time
  senderId: string | AnonymousGuest;
  textBody: string;
  contentType?: 'image';
  attachments: IChatAttachment[];
  status: 'delivered' | 'processing' | 'failed';
};

type IChatMessageGenAIAssistantThreadMessage = IChatMessageShared & {
  type: 'GenAIAssistantThreadMessage';
  robotCustomActionMatchResult: RobotCustomActionMatchResult | null;
}

type IChatMessageUserWhatsAppMessage = IChatMessageShared & {
  type: 'UserWhatsAppMessage'
}

export type IChatParticipant = {
  id: string | AnonymousGuest;
  name: string;
  role: Role; // TODO deprecate - used by old FAQ flow
  email: string;
  address: string;
  avatarUrl: string;
  phoneNumber: string;
  lastActivityIsoDateTime: string;
  status: 'online' | 'offline' | 'alway' | 'busy';
};

export type IChatConversation = {
  id: string;
  unreadCount: number;
  messages: IChatMessage[]; // sorted by date time in ascending order
  otherParticipants: IChatParticipant[];
  meParticipants: IChatParticipant[];
};

export type IChatConversations = {
  byId: Record<string, IChatConversation | undefined>;
  allIds: string[];
};
