import { PayloadAction, createAsyncThunk, createListenerMiddleware, createSlice } from '@reduxjs/toolkit'
import { enqueueErrorSnackbarMessage, enqueueSuccessSnackbarMessage } from '../notifications/snackbar-notification-util';
import { CodespaceProject, CreateCodespaceProjectMutation, CreateCodespaceProjectMutationVariables, GetCodespaceProjectQuery, GetCodespaceProjectQueryVariables, ListCodespaceProjectsByIdQuery, ListCodespaceProjectsByIdQueryVariables, OwnerType, DeleteCodespaceProjectMutationVariables, DeleteCodespaceProjectMutation, S3File, UpdateCodespaceProjectMetadataMutationVariables, UpdateCodespaceProjectMetadataMutation, UpdateCodespaceNotebookMutationVariables, UpdateCodespaceNotebookMutation } from "src/globalUtils/API";
import { AppDispatch, RootState, store } from '../store';
import { routeReplaceOrPushPath } from '../router/routerCustomSlice';
import { paths } from 'src/routes/paths';
import { GraphQLQuery, GraphQLResult } from 'aws-amplify/api';
import { graphQLClient } from '../util';
import * as mutations from 'src/globalUtils/graphql/mutations';
import * as queries from 'src/globalUtils/graphql/queries';
import { uploadFileToS3AndSaveToLocal_DEPRECATED } from 'src/fileStorage/fileManager';
import { CodespaceNotebook } from 'src/globalUtils/globalTypes';

type CodespaceProjectsState = {
    currentProject: CodespaceProject | undefined
    userProjects: CodespaceProject[] | undefined
    perOrganizationProjects: undefined
}

const initialState: CodespaceProjectsState = {
    currentProject: undefined,
    userProjects: undefined,
    perOrganizationProjects: undefined
}

export const codespaceProjectsSlice = createSlice({
    name: 'codespaceProjects',
    initialState,
    reducers: {
        updateCodespaceProject: (state, action: PayloadAction<CodespaceProject>) => {
            state.userProjects = [
                action.payload,
                ...(state.userProjects ?? []).filter(x =>
                    x.id !== action.payload.id)
            ]
            // update current project
            if (state.currentProject) {
                state.currentProject = action.payload
            }
        },
    },
    extraReducers(builder) {
        // fetchCodespaceProjects
        builder.addCase(fetchCodespaceProjects.pending, (state, action) => {
        })
        builder.addCase(fetchCodespaceProjects.fulfilled, (state, action) => {
            const payload = action.payload
            // important - otherwise it will update state even if no value is changed
            if (payload === undefined) {
                return
            }
            state.userProjects = payload
        })
        builder.addCase(fetchCodespaceProjects.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to fetch user Codespace s')
        })

        // createCodespaceProject
        builder.addCase(createCodespaceProject.pending, (state, action) => {
        })
        builder.addCase(createCodespaceProject.fulfilled, (state, action) => {
            const project = action.payload
            if (project.ownerType === OwnerType.USERNAME) {
                state.userProjects = state.userProjects ? [project, ...state.userProjects] : [project]
            }
            else {
                // TODO
            }

            state.currentProject = action.payload

            // emit succcess
            enqueueSuccessSnackbarMessage('Successfully created Codespace ')
        })
        builder.addCase(createCodespaceProject.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to create Codespace ')
        })

        // selectCodespaceProject
        builder.addCase(selectCodespaceProject.pending, (state, action) => {
        })
        builder.addCase(selectCodespaceProject.fulfilled, (state, action) => {
            const { project, fetchedFromServer } = action.payload
            state.currentProject = project
            if (!fetchedFromServer) {
                return
            }
            if (project.ownerType === OwnerType.USERNAME) {
                state.userProjects = [project, ...(state.userProjects ?? [])].sort((left, right) => right!!.updatedAt!!.localeCompare(left!!.updatedAt!!))
            }
            else {
                // TODO for organization
            }
        })
        builder.addCase(selectCodespaceProject.rejected, (state, action) => {
            enqueueErrorSnackbarMessage(`Codespace  doesn't exist or you don't have permission to access`)
        })

        // deleteCodespaceProject
        builder.addCase(deleteCodespaceProject.pending, (state, action) => {
        })
        builder.addCase(deleteCodespaceProject.fulfilled, (state, action) => {
            const { payload } = action
            state.userProjects = (state.userProjects ?? []).filter(x => x.id !== payload.id)


            // emit succcess
            enqueueSuccessSnackbarMessage(`Successfully deleted Codespace  ${payload.id}`)
        })
        builder.addCase(deleteCodespaceProject.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to delete Codespace ')
        })
    }
})

export const fetchCodespaceProjects = createAsyncThunk<
    CodespaceProject[] | undefined,
    {
        ownerID: string,
    }, // input argument - projectName
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('codespaceProjects/fetchCodespaceProjects', async ({ ownerID }, { dispatch, getState }) => {
    // Skip. Already fetching
    // This may have issues when opened one project directly
    // if (getState().codespaceProjects.userProjects) {
    //     return undefined
    // }

    let shouldFetch = true
    let nextToken = null
    let projects: CodespaceProject[] = []

    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        const variables: ListCodespaceProjectsByIdQueryVariables = {
            ownerID,
            nextToken
        }
        // const user = await Auth.currentAuthenticatedUser() as CognitoUser
        const response: GraphQLResult<ListCodespaceProjectsByIdQuery> = await graphQLClient.graphql<GraphQLQuery<ListCodespaceProjectsByIdQuery>>({
            query: queries.listCodespaceProjectsById,
            variables
        });
        nextToken = response.data?.listCodespaceProjectsById?.nextToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        projects = projects.concat(response.data!!.listCodespaceProjectsById!!.items!! as CodespaceProject[])
    }

    return projects
})

export const createCodespaceProject = createAsyncThunk<
    CodespaceProject,
    {
        mutationVariables: CreateCodespaceProjectMutationVariables,
    }, // input argument - projectName
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('codespaceProjects/createCodespaceProject', async ({ mutationVariables }, { dispatch, getState }) => {
    const response: GraphQLResult<CreateCodespaceProjectMutation> = await graphQLClient.graphql<GraphQLQuery<CreateCodespaceProjectMutation>>({
        query: mutations.createCodespaceProject,
        variables: mutationVariables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    const data = response.data.createCodespaceProject!! as CodespaceProject
    return data
})

export const selectCodespaceProject = createAsyncThunk<
    {
        project: CodespaceProject,
        fetchedFromServer: boolean
    },
    {
        projectID: string,
    }, // input argument - projectName
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('codespaceProjects/selectCodespaceProject', async ({ projectID }, { dispatch, getState }) => {
    // is the same as current project
    const currentProject = getState().codespaceProjects?.currentProject
    if (currentProject && projectID === currentProject.id) {
        return {
            project: currentProject,
            fetchedFromServer: false
        }
    }

    // already stored in the projects list
    const existing = getState().codespaceProjects.userProjects?.find(project => project.id === projectID)
    if (existing) {
        return {
            project: existing,
            fetchedFromServer: false
        }
    }

    const variables: GetCodespaceProjectQueryVariables = {
        id: projectID
    }
    const response: GraphQLResult<GetCodespaceProjectQuery> = await graphQLClient.graphql<GraphQLQuery<GetCodespaceProjectQuery>>({
        query: queries.getCodespaceProject,
        variables
    });

    const project = response.data.getCodespaceProject

    if (!project) {
        throw new Error("Project doesn't exist or user have no permission")
    }

    return {
        project,
        fetchedFromServer: true
    }
})

type updateCodespaceProjectMetadataProps = UpdateCodespaceProjectMetadataMutationVariables

export const updateCodespaceProjectMetadata = async ({ id, updatedProjectName }: updateCodespaceProjectMetadataProps) => {
    try {
        const variables: UpdateCodespaceProjectMetadataMutationVariables = {
            id,
            updatedProjectName,
        }
        const response: GraphQLResult<UpdateCodespaceProjectMetadataMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateCodespaceProjectMetadataMutation>>({
            query: mutations.updateCodespaceProjectMetadata,
            variables
        });

        const data = response.data.updateCodespaceProjectMetadata

        if (!data || response.errors) {
            throw new Error("Error updating Codespace project")
        }

        store.dispatch(updateCodespaceProject(data))
        enqueueSuccessSnackbarMessage('Updated')
    }
    catch (error) {
        enqueueErrorSnackbarMessage('Failed to update')
    }
}

type saveCodespaceNotebookProps = UpdateCodespaceNotebookMutationVariables & {
    updatedNotebook: CodespaceNotebook
}
export const saveCodespaceNotebook = async ({ id, updatedNotebook }: saveCodespaceNotebookProps) => {
    try {
        const variables: UpdateCodespaceNotebookMutationVariables = {
            id,
        }
        const response: GraphQLResult<UpdateCodespaceNotebookMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateCodespaceNotebookMutation>>({
            query: mutations.updateCodespaceNotebook,
            variables
        });

        const data = response.data.updateCodespaceNotebook

        if (!data || response.errors) {
            throw new Error("Error saving notebook")
        }
        // update the notebook with s3 first before saving the project model to local redux store
        await uploadFileToS3AndSaveToLocal_DEPRECATED({
            s3File: data.updatedProject.notebookS3File,
            s3PreSignedUrl: data.notebookUploadLink.s3PreSignedUrl,
            file: new Blob([JSON.stringify(updatedNotebook)], { type: "application/json" }),
        })
        store.dispatch(updateCodespaceProject(data.updatedProject))

        enqueueSuccessSnackbarMessage('Saved')
        return data
    }
    catch (error) {
        enqueueErrorSnackbarMessage('Failed to save')
        throw error
    }
}

export const deleteCodespaceProject = createAsyncThunk<
    DeleteCodespaceProjectMutationVariables,
    DeleteCodespaceProjectMutationVariables, // input argument - projectName
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('codespaceProjects/deleteCodespaceProject', async (input: DeleteCodespaceProjectMutationVariables, { dispatch, getState }) => {
    const { id } = input
    const variables: DeleteCodespaceProjectMutationVariables = {
        id
    }
    const response: GraphQLResult<DeleteCodespaceProjectMutation> = await graphQLClient.graphql<GraphQLQuery<DeleteCodespaceProjectMutation>>({
        query: mutations.deleteCodespaceProject,
        variables
    });

    if (response.data.deleteCodespaceProject) {
        // success
        return input
    }
    throw new Error(`Failed to delete project ${id}`)
})

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    actionCreator: createCodespaceProject.fulfilled,
    effect: async (action, listenerApi) => {
        store.dispatch(selectCodespaceProject({
            projectID: action.payload.id,
        }))
    },
});

listenerMiddleware.startListening({
    actionCreator: selectCodespaceProject.rejected,
    effect: async (action, listenerApi) => {
        store.dispatch(routeReplaceOrPushPath(paths.page404))
    }
})

export const { updateCodespaceProject } = codespaceProjectsSlice.actions

export default codespaceProjectsSlice.reducer