import { DeleteMachineLearningProjectMutationVariables, GetMachineLearningProjectV2QueryVariables, MachineLearningModelType, OrgRole } from "src/globalUtils/API";

// ----------------------------------------------------------------------
const ROBOT_MANAGEMENT_ROOT = '/robot_management'

const ROOTS = {
  AUTH: '/auth',
  ROBOT_MANAGEMENT: {
    ROOT: ROBOT_MANAGEMENT_ROOT,
    DASHBOARD: `${ROBOT_MANAGEMENT_ROOT}/dashboard`,
    MINI_APPS: `${ROBOT_MANAGEMENT_ROOT}/miniApps`,
    CONVERSATION_HISTORY: `${ROBOT_MANAGEMENT_ROOT}/conversation_history`
  },
  MANAGEMENT: '/management',
  CODELAB: '/codelab',
  MACHINE_LEARNING_PROJECT_ROOT: '/codelab/machine-learning/project',
  CODESPACE_PROJECT_ROOT: '/codelab/codespace/project',
  GENAI: `/codelab/gen-ai`,
  QUIZ_CATALOG: `/codelab/quiz-catalog`
};

// ----------------------------------------------------------------------
// DON't change tab value
export enum ClassTabValue {
  COURSE_INFO = "Course Info",
  PROGRESS_REPORTS = "Progress Reports",
  ADMIN = "Admin"
}

// DON't change tab value
export enum CodeLabTabValue {
  HOME = "Home",
  COPY = "Copy"
}

export type NavigationTabValue = ClassTabValue | CodeLabTabValue

export const robotManagementDashboardChatThreadQueryParamThreadId = 'threadId'
export const paths = {
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  pageUnauthorized: '/unauthorized', // TODO
  home: '/',
  // AUTH
  auth: {
    root: ROOTS.AUTH,
    amplify: {
      login: `${ROOTS.AUTH}/login`,
      verify: `${ROOTS.AUTH}/verify`,
      register: `${ROOTS.AUTH}/register`,
      newPassword: `${ROOTS.AUTH}/new-password`,
      forgotPassword: `${ROOTS.AUTH}/forgot-password`,
      invited_registration: `${ROOTS.AUTH}/invited_registration`
    },
  },
  // ROBOT MANAGEMENT
  robotManagement: {
    root: ROOTS.ROBOT_MANAGEMENT.ROOT,
    dashboard: ROOTS.ROBOT_MANAGEMENT.DASHBOARD,
    dashboardChatThread: (threadId: string) => `${ROOTS.ROBOT_MANAGEMENT.DASHBOARD}?${robotManagementDashboardChatThreadQueryParamThreadId}=${threadId}`,
    miniApps: ROOTS.ROBOT_MANAGEMENT.MINI_APPS
  },
  // CODELAB
  codelab: {
    // root: ROOTS.CODELAB, // DON't use root for all projects
    allProjects: `${ROOTS.CODELAB}/all-projects`,
    project: (id: string) => `${ROOTS.CODELAB}/project/${id}`,
    machineLearning: {
      // root: `${ROOTS.CODELAB}/machine-learning`, // DON't use root for all-projects
      allProjects: `${ROOTS.CODELAB}/machine-learning/all-projects`,
      project: (id: string) => `${ROOTS.MACHINE_LEARNING_PROJECT_ROOT}/${id}`,
      projectPath: ROOTS.MACHINE_LEARNING_PROJECT_ROOT,
    },
    codespace: {
      allCodespaceProjects: `${ROOTS.CODELAB}/codespace/all-projects`,
      project: (id: string) => `${ROOTS.CODESPACE_PROJECT_ROOT}?id=${id}`,
      projectPath: ROOTS.CODESPACE_PROJECT_ROOT,
    },
    scratch: {
      home: `${ROOTS.CODELAB}/scratch/home`,
    },
    genAI: {
      root: `${ROOTS.GENAI}`,
      knowledgeBase: {
        all: `${ROOTS.GENAI}/knowledgeBase/all`,
        view: (knowledgeBaseID: string) => `${ROOTS.GENAI}/knowledgeBase/${knowledgeBaseID}/view`,
        // edit: (id: string) => `${ROOTS.GENAI}/knowledgeBase/${id}/edit`, -- disabled: use dialog instead
        customAction: {
          all: (knowledgeBaseID: string) => `${ROOTS.GENAI}/knowledgeBase/${knowledgeBaseID}/customAction/all`,
          view: ({ knowledgeBaseID, customActionID }: { knowledgeBaseID: string, customActionID: string }) => `${ROOTS.GENAI}/knowledgeBase/${knowledgeBaseID}/customAction/${customActionID}`,
          // edit: disabled - use dialog instead
        }
      },
      assistant: {
        root: `${ROOTS.GENAI}/assistant/`,
        all: `${ROOTS.GENAI}/assistant/all`,
        view: (assistantID: string) => `${ROOTS.GENAI}/assistant/${assistantID}/view`,
        // edit: disabled - use dialog instead
      }
    },
    quizCatalog: {
      root: `${ROOTS.QUIZ_CATALOG}`,
      all: `${ROOTS.QUIZ_CATALOG}/all`,
      view: ({ quizCatalogID }: { quizCatalogID: string }) => `${ROOTS.QUIZ_CATALOG}/${quizCatalogID}`,
      quiz: {
        view: ({ quizCatalogID, quizID }: { quizCatalogID: string; quizID: string }) => `${ROOTS.QUIZ_CATALOG}/${quizCatalogID}/quiz/${quizID}`,
      }
      // edit: disabled - use dialog instead
    }
  },
  // MANAGEMENT
  management: {
    root: `${ROOTS.MANAGEMENT}`,
    user: {
      root: `${ROOTS.MANAGEMENT}/user`,
      list: `${ROOTS.MANAGEMENT}/user/list`,
      view: (username: string) => `${ROOTS.MANAGEMENT}/user/${username}/view`,
      edit: (username: string) => `${ROOTS.MANAGEMENT}/user/${username}/edit`,
      create: `${ROOTS.MANAGEMENT}/user/create`
    },
    curriculum: {
      root: `${ROOTS.MANAGEMENT}/curriculum`,
      list: `${ROOTS.MANAGEMENT}/curriculum/list`,
      view: (curriculumId: string) => `${ROOTS.MANAGEMENT}/curriculum/${curriculumId}/view`,
      edit: (curriculumId: string) => `${ROOTS.MANAGEMENT}/curriculum/${curriculumId}/edit`,
      create: `${ROOTS.MANAGEMENT}/curriculum/create`
    },
    class: {
      root: `${ROOTS.MANAGEMENT}/class`,
      list: `${ROOTS.MANAGEMENT}/class/list`,
      viewWithDefaultTab: (classId: string) => `${ROOTS.MANAGEMENT}/class/${classId}/view`,
      view: (classId: string, tab: ClassTabValue) => `${ROOTS.MANAGEMENT}/class/${classId}/view?tab=${tab}`,
      edit: (classId: string) => `${ROOTS.MANAGEMENT}/class/${classId}/edit`,
      create: `${ROOTS.MANAGEMENT}/class/create`,
    },
    centre: {
      root: `${ROOTS.MANAGEMENT}/centre`,
      list: `${ROOTS.MANAGEMENT}/centre/list`,
      view: (centreId: string) => `${ROOTS.MANAGEMENT}/centre/${centreId}/view`,
      edit: (centreId: string) => `${ROOTS.MANAGEMENT}/centre/${centreId}/edit`,
      create: `${ROOTS.MANAGEMENT}/centre/create`
    },
    classroom: {
      root: `${ROOTS.MANAGEMENT}/classroom`,
      list: `${ROOTS.MANAGEMENT}/classroom/list`,
      view: (classroomId: string) => `${ROOTS.MANAGEMENT}/classroom/${classroomId}/view`,
      edit: (classroomId: string) => `${ROOTS.MANAGEMENT}/classroom/${classroomId}/edit`,
      create: `${ROOTS.MANAGEMENT}/classroom/create`
    },
    award: {
      root: `${ROOTS.MANAGEMENT}/award`,
      list: `${ROOTS.MANAGEMENT}/award/list`,
      view: (awardId: string) => `${ROOTS.MANAGEMENT}/award/${awardId}/view`,
      edit: (awardId: string) => `${ROOTS.MANAGEMENT}/award/${awardId}/edit`,
      create: `${ROOTS.MANAGEMENT}/award/create`
    },
    messages: {
      root: `${ROOTS.MANAGEMENT}/messages`,
      list: `${ROOTS.MANAGEMENT}/messages/list`,
    }
  }
};
