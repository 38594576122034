import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../../store"
import { DeleteQuizCatalogsMutation, DeleteQuizCatalogsMutationVariables, QuizCatalog } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from "aws-amplify/api"
import * as mutations from 'src/globalUtils/graphql/mutations';
import { graphQLClient } from "../../util";

export const deleteQuizCatalogs = createAsyncThunk<
    {
        organizationID: string,
        deletedQuizCatalogIDs: string[],
    }, // output
    {
        quizCatalogIDsToDelete: string[],
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/deleteQuizCatalogs', async ({ quizCatalogIDsToDelete }, { dispatch, getState }) => {
    const organizationID = (getState().authentication.currentSelectedOrganizationID!!)
    const variables: DeleteQuizCatalogsMutationVariables = {
        ids: quizCatalogIDsToDelete
    }
    const response: GraphQLResult<DeleteQuizCatalogsMutation> = await graphQLClient.graphql<GraphQLQuery<DeleteQuizCatalogsMutation>>({
        query: mutations.deleteQuizCatalogs,
        variables
    });
    if (response.errors || response.data.deleteQuizCatalogs != null) {
        throw new Error(response.data.deleteQuizCatalogs ?? "Failed to delete quiz catalogs")
    }
    return {
        organizationID,
        deletedQuizCatalogIDs: quizCatalogIDsToDelete
    }
})