import { push } from "@lagunovsky/redux-react-router";
import { AppDispatch, RootState, store } from "../store";
import { get } from "lodash";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProcessingSpinner {
    text?: string;
}

interface InitialState {
    processingSpinner?: ProcessingSpinner
}

const initialState: InitialState = {}

export const overlaysSlice = createSlice({
    // don't use router as name as it is reserved by 'history' package
    name: 'overlays',
    initialState,
    reducers: {
        enableProcessingSpinner: (state, action: PayloadAction<ProcessingSpinner>) => {
            state.processingSpinner = action.payload
        },
        cancelProcessingSpinner: (state, action: PayloadAction<void>) => {
            state.processingSpinner = undefined
        },
    },
    extraReducers(builder) {
    }
});

export const { enableProcessingSpinner, cancelProcessingSpinner } = overlaysSlice.actions

export default overlaysSlice.reducer