import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { KnowledgeBase, ListKnowledgeBasesByOwnerIDQuery, ListKnowledgeBasesByOwnerIDQueryVariables, OrganizationDataForUser, OrgRole, OwnerType, WebsiteKnowledgeBaseUrl, UpdateWebsiteKnowledgeBaseUrlEnabledDecisionMutationVariables, UpdateWebsiteKnowledgeBaseUrlEnabledDecisionMutation } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as mutations from 'src/globalUtils/graphql/mutations';
import { RootState, AppDispatch, store } from '../../../store'
import { graphQLClient } from '../../../util'

export const updateWebsiteKnowledgeBaseUrlEnabledDecision = createAsyncThunk<
    {
        orgId: string
    }, // output
    UpdateWebsiteKnowledgeBaseUrlEnabledDecisionMutationVariables, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/updateWebsiteKnowledgeBaseUrlEnabledDecision', async (variables, { dispatch, getState }) => {

    const response: GraphQLResult<UpdateWebsiteKnowledgeBaseUrlEnabledDecisionMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateWebsiteKnowledgeBaseUrlEnabledDecisionMutation>>({
        query: mutations.updateWebsiteKnowledgeBaseUrlEnabledDecision,
        variables
    });

    if (response.errors) {
        throw new Error("Failed to update URL decisions. Reason unknown")
    }

    if (response.data.updateWebsiteKnowledgeBaseUrlEnabledDecision.errorMessage) {
        throw new Error(response.data.updateWebsiteKnowledgeBaseUrlEnabledDecision.errorMessage)
    }

    return {
        orgId: getState().authentication.currentSelectedOrganizationID!!
    }
})
