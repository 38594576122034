import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { GetKnowledgeBaseByIDsQuery, GetKnowledgeBaseByIDsQueryVariables, KnowledgeBase, } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from 'src/globalUtils/graphql/queries';
import { RootState, AppDispatch, store } from '../../store'
import { graphQLClient } from '../../util'

export type GetKnowledgeBasesByIdsOutput = {
    organizationID: string,
    knowledgeBases: KnowledgeBase[]
}

export const getKnowledgeBasesByIds = createAsyncThunk<
    GetKnowledgeBasesByIdsOutput, // output
    {
        ids: string[];
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/getKnowledgeBasesByIds', async ({ ids }, { dispatch, getState }) => {
    const currentOrganizationID = getState().authentication.currentSelectedOrganizationID!!

    const variables: GetKnowledgeBaseByIDsQueryVariables = {
        ids
    };

    const response = await graphQLClient.graphql<GraphQLQuery<GetKnowledgeBaseByIDsQuery>>({
        query: queries.getKnowledgeBaseByIDs,
        variables,
    })

    if (response.errors) {
        throw new Error('Failed to load knowledge base')
    }
    return {
        organizationID: currentOrganizationID,
        knowledgeBases: response.data.getKnowledgeBaseByIDs
    }
})
