import { uploadData, downloadData, remove } from 'aws-amplify/storage'
import * as ProfileFiles from '../store/blocklyProjects/projectFiles'
// eslint-disable-next-line import/extensions
import { DocumentType, FileCategory, FileType, OwnerType, S3File } from 'src/globalUtils/API';
import { getFilesFromLocalOrRemote, LocalFile, uploadFilesToS3AndSaveToLocalV2 } from './fileManager';
import { ProjectFile } from '../store/blocklyProjects/projectFiles';

function getBlocklyProjectS3Key(projectId: string, fileName: string, fileCategory: FileCategory) {
    return `blockly-projects/${projectId}/${fileCategory}/${fileName}`
}

export const TEACHABLE_MACHINE_EXTENSIONS = new Set(['.zip'])
export const IMAGE_EXTENSIONS = new Set(['.jpg', '.jpeg', '.png', '.gif', '.svg', '.webp', '.ico'])
export const VIDEO_EXTENSIONS = new Set(['.mp4', '.mov', '.avi', '.mkv', '.webm'])
export const AUDIO_EXTENSIONS = new Set(['.mp3', '.wav', '.ogg', '.acc'])


// downloadable also means previewable
export function isFileDownloadableAndPreviewable(fileName: string): boolean {
    // TODO this approach of converting to lowercase is still a bit error prone
    return IMAGE_EXTENSIONS.has(('.' + fileName.split('.').pop()).toLowerCase())
}

export enum S3FileDownloadErrorType {
    FILE_DOES_NOT_EXIST = "FILE_DOES_NOT_EXIST",
    OTHERS = "OTHERS"
}

export type S3FileDownloadResult = {
    file?: File,
    category: FileCategory,
    errorType?: S3FileDownloadErrorType,
    errorMessage?: string
}

const STORAGE_ACCESS_LEVEL = 'protected'

function is404Error(error: Error): boolean {
    return error.name.includes('NoSuchKey') || error.message.includes('NoSuchKey')
}

const getBlocklyFileS3KeyLegacy = ({ identityId, name, category, lastUpdatedTimestamp }: FileType, projectID: string): S3File => {
    const v = Number(lastUpdatedTimestamp)
    return {
        __typename: "S3File",
        s3Key: `${STORAGE_ACCESS_LEVEL}/${identityId}/${getBlocklyProjectS3Key(projectID, name, category)}`,
        documentType: DocumentType.CODELAB_LEGACY_MIGRATION,
        versionNumber: Number.isNaN(v) ? 0 : v
    }
}

export async function downloadBlocklyProjectFileFromLocalOrS3(fileType: FileType, projectID: string): Promise<ProjectFile | null> {
    try {
        if (!isFileDownloadableAndPreviewable(fileType.name)) {
            throw Error(`file ${fileType.name} is not downloadable`)
        }
        const f = await getFilesFromLocalOrRemote({ s3Files: [getBlocklyFileS3KeyLegacy(fileType, projectID)] })
        return {
            localUrl: f[0].localUrl,
            fileCategory: fileType.category,
            file: f[0].file
        }
    } catch (e) {
        // TODO log error
        console.error(e)
        return null
    }
}

type UploadFileForBlocklyProjectProps = {
    identityId: string;
    lastUpdatedTimestamp: string;
    validatedFile: File,
    projectID: string,
    fileCategory: FileCategory,
    completeCallback: () => any,
    errorCallback: (err: any) => any
}

// TODO replace
export async function uploadBlocklyProjectFileToS3({ identityId, validatedFile, fileCategory, projectID, lastUpdatedTimestamp, completeCallback, errorCallback }: UploadFileForBlocklyProjectProps) {
    try {
        console.log('content type:')
        console.log(validatedFile.type)
        await uploadFilesToS3AndSaveToLocalV2({
            inputs: [{
                ownerID: 'na', // doesn't matter
                ownerType: OwnerType.USERNAME, // doesn't matter
                file: validatedFile,
                fileName: 'na', // doesn't matter
                contentType: validatedFile.type,
                documentType: DocumentType.CODELAB_LEGACY_MIGRATION, // doesn't matter
                maybeExistingS3Key: getBlocklyFileS3KeyLegacy({ __typename: "FileType", identityId, name: validatedFile.name, category: fileCategory, lastUpdatedTimestamp }, projectID).s3Key,
            }]
        })

        completeCallback()
    }
    catch (error) {
        errorCallback(error)
    }
}

// TODO remove can fail if file doesn't belong to the identity
// don't remove from S3 so that coped/cloned project won't fail. - easier solution. Also, assuming most users don't delete files..
// export async function removeBlocklyProjectFileFromS3(validatedFileName: string, projectID: string, fileCategory: FileCategory) {
//     try {
//         // TODO replace
//         await remove({
//             path: ({ identityId }) => `${STORAGE_ACCESS_LEVEL}/${identityId}/${getBlocklyProjectS3Key(projectID, validatedFileName, fileCategory)}`
//         });
//     } catch (e) {
//         // TODO log. This can happen when file doesn't exist - so we only log non-404 cases
//         if (!is404Error(e as Error)) {
//             console.log(e)
//         }
//     }
// }