import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../../store"
import { LyzaAppMiniApp, UpdateLyzaAppMiniAppCustomMutation, UpdateLyzaAppMiniAppCustomMutationVariables } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from "aws-amplify/api"
import * as mutations from 'src/globalUtils/graphql/mutations';
import { graphQLClient } from "../../util";

export const updateLyzaAppMiniApp = createAsyncThunk<
    {
        organizationID: string,
        updatedLyzaAppMiniApp: LyzaAppMiniApp,
    }, // output
    {
        updateLyzaAppMiniAppVariables: UpdateLyzaAppMiniAppCustomMutationVariables
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/updateLyzaAppMiniApp', async ({ updateLyzaAppMiniAppVariables }, { dispatch, getState }) => {
    const { currentSelectedOrganizationID } = getState().authentication
    const response: GraphQLResult<UpdateLyzaAppMiniAppCustomMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateLyzaAppMiniAppCustomMutation>>({
        query: mutations.updateLyzaAppMiniAppCustom,
        variables: updateLyzaAppMiniAppVariables
    });
    if (response.errors) {
        throw new Error("Failed to update knowledge base")
    }
    return {
        organizationID: currentSelectedOrganizationID!!,
        updatedLyzaAppMiniApp: response.data.updateLyzaAppMiniAppCustom
    }
})