import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../../store"
import { BlocklyProject, CreateLyzaAppMiniAppCustomMutation, CreateLyzaAppMiniAppCustomMutationVariables, LyzaAppMiniApp } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from "aws-amplify/api"
import * as mutations from 'src/globalUtils/graphql/mutations';
import { graphQLClient } from "../../util";
import { saveCodelabProject } from "src/store/blocklyProjects/blocklyProjectsSlice";

export const createLyzaAppMiniApp = createAsyncThunk<
    {
        organizationID: string;
        createdLyzaAppMiniApp: LyzaAppMiniApp;
        createdBlocklyProject: BlocklyProject;
    }, // output
    {
        createLyzaAppMiniAppVariables: CreateLyzaAppMiniAppCustomMutationVariables,
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/createLyzaAppMiniApp', async ({ createLyzaAppMiniAppVariables }, { dispatch, getState }) => {
    const response: GraphQLResult<CreateLyzaAppMiniAppCustomMutation> = await graphQLClient.graphql<GraphQLQuery<CreateLyzaAppMiniAppCustomMutation>>({
        query: mutations.createLyzaAppMiniAppCustom,
        variables: createLyzaAppMiniAppVariables
    });
    if (response.errors) {
        throw new Error("Failed to create Mini App")
    }

    await dispatch(saveCodelabProject({
        project: response.data.createLyzaAppMiniAppCustom.createdBlocklyProject
    }))

    return {
        organizationID: createLyzaAppMiniAppVariables.orgID,
        createdLyzaAppMiniApp: response.data.createLyzaAppMiniAppCustom.createdLyzaAppMiniApp,
        createdBlocklyProject: response.data.createLyzaAppMiniAppCustom.createdBlocklyProject
    }
})