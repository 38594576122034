import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { QuizCatalog, ListQuizCatalogsByOwnerIDQuery, ListQuizCatalogsByOwnerIDQueryVariables, OrganizationDataForUser, OrgRole, OwnerType } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from 'src/globalUtils/graphql/queries';
import { RootState, AppDispatch, store } from '../../store'
import { graphQLClient } from '../../util'
import { getCurrentSelectedOrganization } from '../../authentication/selectedOrganizationHook';
import { updateFetchStatus } from '../robotConciergeSlice';

export const fetchQuizCatalogsKey = 'fetchQuizCatalogs'
export const fetchQuizCatalogs = createAsyncThunk<
    {
        organizationID: string,
        quizCatalogs: QuizCatalog[]
    } | undefined, // output
    {
        userOrg: OrganizationDataForUser
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/fetchQuizCatalogs', async ({ userOrg }, { dispatch, getState }) => {
    const fetchStatus = getState().robotConcierge.perOrganizationStates.find(x => x.organizationID === userOrg.userOrg.organizationID)?.fetchStatus[fetchQuizCatalogsKey]
    if (fetchStatus === 'fulfilled' || fetchStatus === 'pending') {
        return undefined;
    }

    const currentOrganizationID = getState().authentication.currentSelectedOrganizationID!!
    const currentUser = getState().authentication.loggedInUser!!.userName

    dispatch(updateFetchStatus({
        fetchKey: fetchQuizCatalogsKey,
        orgId: userOrg.userOrg.organizationID,
        status: 'pending'
    }))

    const shouldFetchSharedOrgQuizCatalog = [OrgRole.ADMIN, OrgRole.OWNER].includes(userOrg.userOrg.role)
    return {
        organizationID: currentOrganizationID,
        quizCatalogs: [
            ...await fetchQuizCatalogsHelper({ ownerID: currentUser }),
            ...shouldFetchSharedOrgQuizCatalog ? await fetchQuizCatalogsHelper({ ownerID: currentOrganizationID })
                : []
        ]
    }
})

async function fetchQuizCatalogsHelper({
    ownerID,
}: {
    ownerID: string;
}): Promise<QuizCatalog[]> {
    let shouldFetch = true
    let nextToken = null
    let quizCatalogs: QuizCatalog[] = []
    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        // const user = await Auth.currentAuthenticatedUser() as CognitoUser
        const variables: ListQuizCatalogsByOwnerIDQueryVariables = {
            ownerID,
            nextToken
        }
        const response: GraphQLResult<ListQuizCatalogsByOwnerIDQuery> = await graphQLClient.graphql<GraphQLQuery<ListQuizCatalogsByOwnerIDQuery>>({
            query: queries.listQuizCatalogsByOwnerID,
            variables
        });
        nextToken = response.data?.listQuizCatalogsByOwnerID?.nextToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        quizCatalogs = quizCatalogs.concat(response.data!!.listQuizCatalogsByOwnerID!!.items!! as QuizCatalog[])
    }

    return quizCatalogs
}