import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { GenAIAssistant, ListGenAIAssistantsByOwnerIDQuery, ListGenAIAssistantsByOwnerIDQueryVariables, OrganizationDataForUser, OrgRole, OwnerType } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from 'src/globalUtils/graphql/queries';
import { RootState, AppDispatch, store } from '../../store'
import { graphQLClient } from '../../util'
import { getCurrentSelectedOrganization } from '../../authentication/selectedOrganizationHook';
import { updateFetchStatus } from '../robotConciergeSlice';

export const fetchGenAIAssistantsKey = 'fetchGenAIAssistants'
export const fetchGenAIAssistants = createAsyncThunk<
    {
        organizationID: string,
        genAIAssistants: GenAIAssistant[]
    } | undefined, // output
    {
        userOrg: OrganizationDataForUser
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/fetchGenAIAssistants', async ({ userOrg }, { dispatch, getState }) => {
    const fetchStatus = getState().robotConcierge.perOrganizationStates.find(x => x.organizationID === userOrg.userOrg.organizationID)?.fetchStatus[fetchGenAIAssistantsKey]
    if (fetchStatus === 'pending' || fetchStatus === 'fulfilled') {
        return undefined;
    }
    const currentOrganizationID = getState().authentication.currentSelectedOrganizationID!!
    const currentUser = getState().authentication.loggedInUser!!.userName
    const organizationState = getState().robotConcierge.perOrganizationStates?.find(s => s.organizationID === currentOrganizationID)

    dispatch(updateFetchStatus({
        fetchKey: fetchGenAIAssistantsKey,
        orgId: userOrg.userOrg.organizationID,
        status: 'pending'
    }))


    // TODO replace with fine grained permission
    const shouldFetchSharedOrgGenAIAssistant = [OrgRole.ADMIN, OrgRole.OWNER].includes(userOrg.userOrg.role)
    return {
        organizationID: currentOrganizationID,
        genAIAssistants: [
            ...await fetchGenAIAssistantsHelper({ ownerID: currentUser }),
            ...shouldFetchSharedOrgGenAIAssistant ? await fetchGenAIAssistantsHelper({ ownerID: currentOrganizationID })
                : []
        ]
    }
})

async function fetchGenAIAssistantsHelper({
    ownerID,
}: {
    ownerID: string;
}): Promise<GenAIAssistant[]> {
    let shouldFetch = true
    let nextToken = null
    let genAIAssistants: GenAIAssistant[] = []
    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        // const user = await Auth.currentAuthenticatedUser() as CognitoUser
        const variables: ListGenAIAssistantsByOwnerIDQueryVariables = {
            ownerID,
            nextToken
        }
        const response: GraphQLResult<ListGenAIAssistantsByOwnerIDQuery> = await graphQLClient.graphql<GraphQLQuery<ListGenAIAssistantsByOwnerIDQuery>>({
            query: queries.listGenAIAssistantsByOwnerID,
            variables
        });
        nextToken = response.data?.listGenAIAssistantsByOwnerID?.nextToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        genAIAssistants = genAIAssistants.concat(response.data!!.listGenAIAssistantsByOwnerID!!.items!! as GenAIAssistant[])
    }

    return genAIAssistants
}