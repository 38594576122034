import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../../store"
import { KnowledgeBase, RobotCustomAction, UpdateRobotCustomActionMutation, UpdateRobotCustomActionMutationVariables } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from "aws-amplify/api"
import * as mutations from 'src/globalUtils/graphql/mutations';
import { graphQLClient } from "../../util";

export const updateRobotCustomAction = createAsyncThunk<
    {
        organizationID: string,
        updatedRobotCustomAction: RobotCustomAction,
        updatedKnowledgeBase: KnowledgeBase
    }, // output
    {
        updateRobotCustomActionVariables: UpdateRobotCustomActionMutationVariables
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/updateRobotCustomAction', async ({ updateRobotCustomActionVariables }, { dispatch, getState }) => {
    const { currentSelectedOrganizationID } = getState().authentication
    const response: GraphQLResult<UpdateRobotCustomActionMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateRobotCustomActionMutation>>({
        query: mutations.updateRobotCustomAction,
        variables: updateRobotCustomActionVariables
    });
    if (response.errors) {
        throw new Error("Failed to update custom action")
    }
    return {
        organizationID: currentSelectedOrganizationID!!,
        updatedRobotCustomAction: response.data.updateRobotCustomAction.updatedRobotCustomAction,
        updatedKnowledgeBase: response.data.updateRobotCustomAction.updatedKnowledgeBase
    }
})