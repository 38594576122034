import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { QuizCatalog, ListQuizCatalogsByOwnerIDQuery, ListQuizCatalogsByOwnerIDQueryVariables, OrganizationDataForUser, OrgRole, OwnerType, ListQuizesByCatalogIDQueryVariables, ListQuizesByCatalogIDQuery, Quiz } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from 'src/globalUtils/graphql/queries';
import { RootState, AppDispatch, store } from '../../../store'
import { graphQLClient } from '../../../util'
import { getCurrentSelectedOrganization } from '../../../authentication/selectedOrganizationHook';
import { updateFetchStatus } from '../../robotConciergeSlice';

export const fetchQuizesByCatalogIDKey = (catalogID: string) => `fetchQuizesByCatalogID-${catalogID}`
export const fetchQuizesByCatalogID = createAsyncThunk<
    {
        organizationID: string;
        quizes: Quiz[]
    } | undefined, // output
    {
        quizCatalogID: string,
        userOrg: OrganizationDataForUser
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/fetchQuizesByCatalogID', async ({ userOrg, quizCatalogID }, { dispatch, getState }) => {
    const fetchStatus = getState().robotConcierge.perOrganizationStates.find(x => x.organizationID === userOrg.userOrg.organizationID)?.fetchStatus[fetchQuizesByCatalogIDKey(quizCatalogID)]
    if (fetchStatus === 'fulfilled' || fetchStatus === 'pending') {
        return undefined;
    }

    dispatch(updateFetchStatus({
        fetchKey: fetchQuizesByCatalogIDKey(quizCatalogID),
        orgId: userOrg.userOrg.organizationID,
        status: 'pending'
    }))

    let shouldFetch = true
    let nextToken = null
    let quizes: Quiz[] = []
    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        // const user = await Auth.currentAuthenticatedUser() as CognitoUser
        const variables: ListQuizesByCatalogIDQueryVariables = {
            catalogID: quizCatalogID,
            nextToken
        }
        const response: GraphQLResult<ListQuizesByCatalogIDQuery> = await graphQLClient.graphql<GraphQLQuery<ListQuizesByCatalogIDQuery>>({
            query: queries.listQuizesByCatalogID,
            variables
        });
        nextToken = response.data?.listQuizesByCatalogID?.nextToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        quizes = quizes.concat(response.data!!.listQuizesByCatalogID!!.items!! as Quiz[])
    }

    return {
        organizationID: userOrg.userOrg.organizationID,
        quizes
    }
})