import { LabelColor } from "src/components/label";
import { OwnerType } from "src/globalUtils/API";

/**
 * Truncate the text to the specified maximum length and append an ellipsis if needed.
 * @param text The text to be truncated.
 * @param maxLength The maximum allowed length of the text.
 * @returns The truncated text with ellipsis if it exceeds the maxLength.
 */
export function truncateText(text: string, maxLength: number): string {
    if (text.length <= maxLength) {
        return text;
    }
    return text.substring(0, maxLength - 3) + '...';
}

export type OwnerTypeWithAll = 'all' | OwnerType
export function getReadableOwnerTypeTabLabel(ownerType: OwnerTypeWithAll): { text: string; labelColor: LabelColor } {
    switch (ownerType) {
        case 'all':
            return {
                text: 'All',
                labelColor: 'default'
            }
        case OwnerType.GROUP:
        case OwnerType.ORGANIZATION:
            return {
                text: 'Shared',
                labelColor: 'info'
            }
        case OwnerType.USERNAME:
            return {
                text: 'Personal',
                labelColor: 'secondary'
            }
        default:
            // This should never be reached if all cases are covered
            const exhaustiveCheck: never = ownerType;
            throw new Error(`Unhandled case: ${exhaustiveCheck}`);
    }
}