import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../../store"
import { CreateQuizCatalogMutation, CreateQuizCatalogMutationVariables, QuizCatalog } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from "aws-amplify/api"
import * as mutations from 'src/globalUtils/graphql/mutations';
import { graphQLClient } from "../../util";

export type CreateQuizCatalogOutput = {
    organizationID: string;
    createdQuizCatalog: QuizCatalog;
}
export const createQuizCatalog = createAsyncThunk<
    CreateQuizCatalogOutput, // output
    {
        orgID: string;
        createQuizCatalogVariables: CreateQuizCatalogMutationVariables,
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/createQuizCatalog', async ({ orgID, createQuizCatalogVariables }, { dispatch, getState }) => {
    const response: GraphQLResult<CreateQuizCatalogMutation> = await graphQLClient.graphql<GraphQLQuery<CreateQuizCatalogMutation>>({
        query: mutations.createQuizCatalog,
        variables: createQuizCatalogVariables
    });
    if (response.errors) {
        throw new Error("Failed to create quiz catalog")
    }
    return {
        organizationID: orgID,
        createdQuizCatalog: response.data.createQuizCatalog
    }
})