import { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { CloseIcon } from 'yet-another-react-lightbox';
import QRCode from "react-qr-code";
import { store, RootState } from '../../../store/store';
import { saveProjectWorkspace } from '../../../store/blocklyProjects/blocklyProjectsSlice';
import NavBarButton from '../../../ui/navBar/NavBarButton';
import Box from '@mui/material/Box';

interface QRCodeWrapperProps {
    valueToEncode: string;
}

function QRCodeWrapper({ valueToEncode }: QRCodeWrapperProps) {
    return (
        <div style={{ height: "auto", margin: "0 auto", maxWidth: 256, width: "100%" }}>
            <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={valueToEncode}
                viewBox={`0 0 256 256`}
            />
        </div>
    );
}

export default function GenerateQRCodeButton() {
    const workspace = useSelector((state: RootState) => state.blocklyProjects.currentWorkspace);
    const [modalShow, setModalShow] = useState(false);
    const onHide = () => setModalShow(false);
    const onShow = () => setModalShow(true);
    const generateQRCodeButtonPressed = () => {
        store.dispatch(saveProjectWorkspace())
            .then(result => {
                if (result.meta.requestStatus === 'fulfilled') {
                    onShow()
                }
            })
    };

    return (
        <>
            {workspace && (
                <>
                    <NavBarButton
                        onClick={generateQRCodeButtonPressed}
                        text={"QRCode"}
                        icon='qrcode'
                    />
                    <Dialog
                        open={modalShow}
                        maxWidth="xs"
                        fullWidth
                        onClose={(_, reason) => {
                            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                                onHide();
                            }
                        }}
                    >
                        <DialogTitle sx={{ pb: 2, textAlign: 'center' }}>
                            <Typography variant="h5" gutterBottom>
                                Project QR Code
                            </Typography>
                            <IconButton
                                aria-label="close"
                                onClick={onHide}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Box pb={3}>
                                <QRCodeWrapper valueToEncode={JSON.stringify({
                                    id: workspace.id,
                                    timestamp: workspace.updatedAt
                                })} />
                            </Box>
                        </DialogContent>
                    </Dialog>
                </>
            )}
        </>
    );
}
