import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { KebbiDeviceForOrganization, ListKebbiDevicesForOrganizationQueryVariables, ListKebbiDevicesForOrganizationQuery } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from 'src/globalUtils/graphql/queries';
import { RootState, AppDispatch, store } from '../store'
import { graphQLClient } from '../util'
import { getCurrentSelectedOrganization } from '../authentication/selectedOrganizationHook';
import { updateFetchStatus } from './robotConciergeSlice';

export const getOrganizationRobotsKey = 'getOrganizationRobots'
export const getOrganizationRobots = createAsyncThunk<
    {
        organizationID: string,
        kebbiDevicesForOrganization: KebbiDeviceForOrganization[]
    } | undefined, // output
    {
        organizationID: string,
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/getOrganizationRobots', async ({ organizationID }, { dispatch, getState }) => {
    const organizationState = getState().robotConcierge.perOrganizationStates?.find(s => s.organizationID === organizationID)
    if (organizationState?.fetchStatus[getOrganizationRobotsKey] === 'pending') {
        return undefined;
    }

    // already fetched
    if (organizationState?.robotStatesByRobotId) {
        return undefined
    }

    dispatch(updateFetchStatus({
        fetchKey: getOrganizationRobotsKey,
        orgId: organizationID,
        status: 'pending'
    }))

    let shouldFetch = true
    let nextToken = null
    let kebbiDevicesForOrganization: KebbiDeviceForOrganization[] = []
    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        // const user = await Auth.currentAuthenticatedUser() as CognitoUser
        const variables: ListKebbiDevicesForOrganizationQueryVariables = {
            organizationID,
            nextToken
        }
        const response: GraphQLResult<ListKebbiDevicesForOrganizationQuery> = await graphQLClient.graphql<GraphQLQuery<ListKebbiDevicesForOrganizationQuery>>({
            query: queries.listKebbiDevicesForOrganization,
            variables
        });
        nextToken = response.data.listKebbiDevicesForOrganization.nextToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        kebbiDevicesForOrganization = kebbiDevicesForOrganization.concat(response.data.listKebbiDevicesForOrganization.items)
    }

    return {
        organizationID,
        kebbiDevicesForOrganization
    }
})

export default getOrganizationRobots