import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { KnowledgeBase, ListKnowledgeBasesByOwnerIDQuery, ListKnowledgeBasesByOwnerIDQueryVariables, OrganizationDataForUser, OrgRole, OwnerType } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from 'src/globalUtils/graphql/queries';
import { RootState, AppDispatch, store } from '../../store'
import { graphQLClient } from '../../util'
import { getCurrentSelectedOrganization } from '../../authentication/selectedOrganizationHook';
import { updateFetchStatus } from '../robotConciergeSlice';

export const fetchKnowledgeBasesKey = 'fetchKnowledgeBases'
export const fetchKnowledgeBases = createAsyncThunk<
    {
        organizationID: string,
        knowledgeBases: KnowledgeBase[]
    } | undefined, // output
    {
        userOrg: OrganizationDataForUser
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/fetchKnowledgeBases', async ({ userOrg }, { dispatch, getState }) => {
    const fetchStatus = getState().robotConcierge.perOrganizationStates.find(x => x.organizationID === userOrg.userOrg.organizationID)?.fetchStatus[fetchKnowledgeBasesKey]
    if (fetchStatus === 'fulfilled' || fetchStatus === 'pending') {
        return undefined;
    }

    const currentOrganizationID = getState().authentication.currentSelectedOrganizationID!!
    const currentUser = getState().authentication.loggedInUser!!.userName

    dispatch(updateFetchStatus({
        fetchKey: fetchKnowledgeBasesKey,
        orgId: userOrg.userOrg.organizationID,
        status: 'pending'
    }))

    // TODO replace with fine grained permission
    const shouldFetchSharedOrgKnowledgeBase = [OrgRole.ADMIN, OrgRole.OWNER].includes(userOrg.userOrg.role)
    return {
        organizationID: currentOrganizationID,
        knowledgeBases: [
            ...await fetchKnowledgeBasesHelper({ ownerID: currentUser }),
            ...shouldFetchSharedOrgKnowledgeBase ? await fetchKnowledgeBasesHelper({ ownerID: currentOrganizationID })
                : []
        ]
    }
})

async function fetchKnowledgeBasesHelper({
    ownerID,
}: {
    ownerID: string;
}): Promise<KnowledgeBase[]> {
    let shouldFetch = true
    let nextToken = null
    let knowledgeBases: KnowledgeBase[] = []
    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        // const user = await Auth.currentAuthenticatedUser() as CognitoUser
        const variables: ListKnowledgeBasesByOwnerIDQueryVariables = {
            ownerID,
            nextToken
        }
        const response: GraphQLResult<ListKnowledgeBasesByOwnerIDQuery> = await graphQLClient.graphql<GraphQLQuery<ListKnowledgeBasesByOwnerIDQuery>>({
            query: queries.listKnowledgeBasesByOwnerID,
            variables
        });
        nextToken = response.data?.listKnowledgeBasesByOwnerID?.nextToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        knowledgeBases = knowledgeBases.concat(response.data!!.listKnowledgeBasesByOwnerID!!.items!! as KnowledgeBase[])
    }

    return knowledgeBases
}