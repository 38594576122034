import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export function OverlaysUI() {
    const processingSpinner = useSelector((state: RootState) => state.overlays.processingSpinner)

    return (
        <Backdrop
            open={!!processingSpinner} // Show the overlay if processingSpinner is defined
            sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1, // Ensure it appears above other elements
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <CircularProgress color="inherit" />
                {processingSpinner?.text && (
                    <Typography
                        variant="h6"
                        sx={{
                            mt: 2, // Add some margin above the text
                            color: "#fff",
                            textAlign: "center",
                        }}
                    >
                        {processingSpinner.text}
                    </Typography>
                )}
            </Box>
        </Backdrop>
    );
}