import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../../../store"
import { CreateQuizesMutation, CreateQuizesMutationVariables, QuizCatalog, Quiz } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from "aws-amplify/api"
import * as mutations from 'src/globalUtils/graphql/mutations';
import { graphQLClient } from "../../../util";

export type CreateQuizesOutput = {
    organizationID: string;
    createdQuizes: Quiz[];
    updatedQuizCatalog: QuizCatalog;
}
export const createQuizes = createAsyncThunk<
    CreateQuizesOutput, // output
    {
        orgID: string;
        createQuizesVariables: CreateQuizesMutationVariables,
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/createQuizes', async ({ orgID, createQuizesVariables }, { dispatch, getState }) => {
    const response: GraphQLResult<CreateQuizesMutation> = await graphQLClient.graphql<GraphQLQuery<CreateQuizesMutation>>({
        query: mutations.createQuizes,
        variables: createQuizesVariables
    });
    if (response.errors || response.data.createQuizes.errorMsg) {
        throw new Error(response.data.createQuizes.errorMsg ?? "Failed to create quizes")
    }
    return {
        organizationID: orgID,
        createdQuizes: response.data.createQuizes.createdOrUpdatedQuizes!!,
        updatedQuizCatalog: response.data.createQuizes.updatedQuizCatalog!!
    }
})