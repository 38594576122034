import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../../../store"
import { DeleteQuizesMutation, DeleteQuizesMutationVariables, Quiz, QuizCatalog } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from "aws-amplify/api"
import * as mutations from 'src/globalUtils/graphql/mutations';
import { graphQLClient } from "../../../util";

export const deleteQuizes = createAsyncThunk<
    {
        organizationID: string,
        deletedQuizesIDs: string[],
        updatedQuizCatalog: QuizCatalog
    }, // output
    {
        variables: DeleteQuizesMutationVariables
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/deleteQuizes', async ({ variables }, { dispatch, getState }) => {
    const organizationID = (getState().authentication.currentSelectedOrganizationID!!)
    const response: GraphQLResult<DeleteQuizesMutation> = await graphQLClient.graphql<GraphQLQuery<DeleteQuizesMutation>>({
        query: mutations.deleteQuizes,
        variables
    });
    if (response.errors || response.data.deleteQuizes.errorMsg != null) {
        throw new Error(response.data.deleteQuizes.errorMsg ?? "Failed to delete quiz catalogs")
    }
    return {
        organizationID,
        deletedQuizesIDs: variables.ids,
        updatedQuizCatalog: response.data.deleteQuizes.updatedQuizCatalog!!
    }
})