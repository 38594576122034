import { Box, Button, ButtonBase, MenuItem, MenuList, Stack, TextField, Typography, useTheme } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { CustomPopoverV2, usePopoverV2 } from "src/components/custom-popover-v2";
import { RootState } from "src/store/store"
import useIsSmallScreen from "./useIsSmallScreen";
import { Iconify } from "src/components/iconify";
import { useCallback, useEffect, useState } from "react";
import { updateActiveNavigationTab } from "src/store/navigation/navigationSlice";
import { useParams, useRouter, useSearchParams } from "src/routes/hooks";
import { ClassTabValue, CodeLabTabValue, NavigationTabValue, paths } from "src/routes/paths";
import { CopyBlocklyProjectButtonDialog } from "src/sections/codelab/buttons/CopyBlocklyProjectButtonDialog";
import { useBoolean } from "src/hooks/use-boolean";

// this is the min we should set.... If buttons overflow -> should convert into dropdown popover
const BUTTON_MAX_WIDTH = 150

export function HeaderHorizontalNavTabs() {
    const navigationData = useSelector((state: RootState) => state.navigation)
    const popover = usePopoverV2();
    const isSmallScreen = useIsSmallScreen()
    const [currentTab, setCurrentTab] = useState<NavigationTabValue | undefined>(undefined)
    const dispatch = useDispatch()

    const { classIdParam } = useParams()
    const blocklyProjectId = useSelector((state: RootState) => state.blocklyProjects.currentWorkspace?.id)
    const searchParams = useSearchParams()

    const router = useRouter()
    const showCodelabCopyProjectModal = useBoolean()

    useEffect(() => {
        if (isSmallScreen) {
            setCurrentTab(navigationData.horizontalNavTabs.find(t => t === navigationData.activeTab))
        }
    }, [isSmallScreen, navigationData.activeTab, navigationData.horizontalNavTabs])

    const tabOnClick = useCallback((tab: NavigationTabValue) => {
        dispatch(updateActiveNavigationTab(tab))
        switch (tab) {
            case ClassTabValue.COURSE_INFO:
            case ClassTabValue.PROGRESS_REPORTS:
            case ClassTabValue.ADMIN:
                router.push(paths.management.class.view(classIdParam!!, tab))
                break;
            case CodeLabTabValue.HOME:
                router.push(paths.home)
                break;
            case CodeLabTabValue.COPY:
                showCodelabCopyProjectModal.onTrue()
                break;
            default:
                const exhaustiveCheck: never = tab;
                throw new Error(`Unhandled case: ${exhaustiveCheck}`);
        }
    }, [classIdParam, dispatch, router, showCodelabCopyProjectModal])

    return <Box mr={1}>
        {
            isSmallScreen ?
                <>
                    {
                        navigationData.horizontalNavTabs.length > 0 && <Button
                            disableRipple
                            onClick={popover.onOpen}
                            sx={{
                                maxWidth: BUTTON_MAX_WIDTH,
                                color: 'text.primary',
                                backgroundColor: 'action.hover',
                                '&:hover': {
                                    backgroundColor: 'action.hover', // Ensure consistent hover behavior
                                },
                            }}
                            endIcon={<Iconify
                                icon="eva:chevron-down-fill"
                                sx={{ flexShrink: 0, color: 'text.disabled' }}
                            />}
                        >
                            <Typography
                                variant='subtitle2'
                                sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                                fontSize={{ xs: 10, sm: '' }}
                            >
                                {currentTab ? getTabDisplayName(currentTab) : ''}
                            </Typography>
                        </Button>
                    }
                </>
                :
                <>
                    {
                        navigationData.horizontalNavTabs.map((tab) => {
                            return (<>
                                <Button
                                    key={tab}
                                    sx={{
                                        textTransform: 'none',
                                        color: navigationData.activeTab === tab ? 'text.primary' : 'text.secondary',
                                        backgroundColor: navigationData.activeTab === tab ? 'action.hover' : 'transparent',
                                        '&:hover': {
                                            backgroundColor: 'action.hover', // Ensure consistent hover behavior
                                        },
                                        maxWidth: BUTTON_MAX_WIDTH,
                                        mr: 0.5
                                    }}
                                    onClick={(event) => {
                                        tabOnClick(tab)
                                    }}
                                >
                                    <Typography
                                        variant={navigationData.activeTab === tab ? 'subtitle2' : 'body2'}
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}
                                    >
                                        {getTabDisplayName(tab)}
                                    </Typography>
                                    {
                                        isSmallScreen &&
                                        <Iconify
                                            icon="eva:chevron-down-fill"
                                            sx={{ ml: 0.5, flexShrink: 0, color: 'text.disabled' }}
                                        />
                                    }
                                </Button>
                                {
                                    tab === CodeLabTabValue.COPY && blocklyProjectId &&
                                    <CopyBlocklyProjectButtonDialog modalShow={showCodelabCopyProjectModal.value} onHide={() => {
                                        dispatch(updateActiveNavigationTab(undefined))
                                        showCodelabCopyProjectModal.onFalse()
                                    }} blocklyProjectId={blocklyProjectId} />
                                }
                            </>)
                        })
                    }
                </>
        }

        {isSmallScreen &&
            <CustomPopoverV2
                disableRestoreFocus
                open={popover.open}
                anchorEl={popover.anchorEl}
                onClose={() => {
                    popover.onClose();
                }}
                slotProps={{
                    paper: {
                        sx: {
                            width: 1,
                            maxWidth: 300,
                            display: 'flex',
                            flexDirection: 'column',
                        },
                    },
                }}
            >
                <MenuList>
                    {navigationData.horizontalNavTabs.map((tab) => {
                        return (
                            <MenuItem
                                key={tab}
                                selected={tab === currentTab}
                                autoFocus={tab === currentTab}
                                onClick={() => {
                                    tabOnClick(tab)
                                    popover.onClose();
                                }}
                            >
                                {getTabDisplayName(tab)}
                            </MenuItem>
                        );
                    })}
                </MenuList>
            </CustomPopoverV2>
        }
    </Box>
}

function getTabDisplayName(tab: NavigationTabValue): string {
    switch (tab) {
        case ClassTabValue.COURSE_INFO:
            return 'Course Info'
        case ClassTabValue.PROGRESS_REPORTS:
            return 'Reports'
        case ClassTabValue.ADMIN:
            return 'Admin'
        case CodeLabTabValue.HOME:
            return 'Home'
        case CodeLabTabValue.COPY:
            return 'Copy'
        default:
            const exhaustiveCheck: never = tab;
            throw new Error(`Unhandled case: ${exhaustiveCheck}`);
    }
}