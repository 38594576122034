import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { copyBlocklyProjectsByID } from "src/store/blocklyProjects/blocklyProjectsSlice";
import { RootState, store } from "src/store/store";
import { AppConstants } from "src/ui/Constants";
import { CloseIcon } from "yet-another-react-lightbox";

export function CopyBlocklyProjectButtonDialog({ modalShow, onHide, blocklyProjectId }: { onHide: () => void; modalShow: boolean; blocklyProjectId: string }) {
    const blocklyProjectNameFromWorkspace = useSelector((state: RootState) => state.blocklyProjects.currentWorkspace?.id === blocklyProjectId ? state.blocklyProjects.currentWorkspace?.projectName : null);
    const blocklyProjectNameFromProjectList = useSelector((state: RootState) => state.blocklyProjects.my_projects?.find(p => p.id === blocklyProjectId)?.projectName);
    const blocklyProjectNameExisting = blocklyProjectNameFromWorkspace ?? blocklyProjectNameFromProjectList;
    const [projectNameTyped, setProjectNameTyped] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [projectNameValidated, setProjectNameValidated] = useState(false);
    const [isDeleteButtonProcessing, setIsDeleteButtonProcessing] = useState(false);

    const handleProjectNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setProjectNameTyped(true);
        setProjectName(value);
        setProjectNameValidated(value.length <= AppConstants.maxProjectNameCharacters && value.trim().length > 0);
    };

    const handleConfirm = async () => {
        setIsDeleteButtonProcessing(true);
        const result = await store.dispatch(copyBlocklyProjectsByID({
            projectIDToCopy: blocklyProjectId,
            projectName,
            groupID: undefined
        }))
        if (result.meta.requestStatus === 'fulfilled') {
            setIsDeleteButtonProcessing(false);
            onHide();
        }
        else {
            setIsDeleteButtonProcessing(false);
        }
    };

    useEffect(() => {
        if (modalShow) {
            // reset state on show
            setProjectNameTyped(false);
            setIsDeleteButtonProcessing(false);
            setProjectNameValidated(true);
            setProjectName(blocklyProjectNameExisting ?? '');
        }
    }, [blocklyProjectNameExisting, modalShow])


    return (<Dialog
        open={modalShow}
        onClose={(_, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                onHide();
            }
        }}
        maxWidth="sm"
        fullWidth
    >
        <DialogTitle sx={{ pb: 2, textAlign: 'center' }}>
            <Typography variant="h5" gutterBottom>
                Copy an Existing Project
            </Typography>
            <IconButton
                aria-label="close"
                onClick={onHide}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <Box mb={3}>
                <Typography variant="subtitle1" gutterBottom>
                    Existing Project Name *
                </Typography>
                <TextField
                    disabled
                    variant="outlined"
                    fullWidth
                    value={blocklyProjectNameExisting ?? ''}
                />
            </Box>
            <Box mb={3}>
                <Typography variant="subtitle1" gutterBottom>
                    New Project Name *
                </Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    autoFocus
                    placeholder="Please enter a project name"
                    value={projectName}
                    onChange={handleProjectNameChange}
                    error={projectNameTyped && !projectNameValidated}
                    helperText={projectNameTyped && !projectNameValidated && `Project name cannot be empty or more than ${AppConstants.maxProjectNameCharacters} characters`}
                />
                <Typography variant="body2" color="textSecondary" align="right">
                    {projectName.length}/{AppConstants.maxProjectNameCharacters}
                </Typography>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={onHide} color="inherit" variant="contained">
                Cancel
            </Button>
            <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleConfirm}
                disabled={!projectNameValidated || isDeleteButtonProcessing}
            >
                Confirm
            </Button>
        </DialogActions>
    </Dialog>)
}