import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { enqueueErrorSnackbarMessage, enqueueSuccessSnackbarMessage } from '../notifications/snackbar-notification-util';
import { PerOrganizationState, RobotConciergeState, RobotStates } from './types';
import { fetchConversationHistory, fetchConversationHistoryKey } from './fetchConversationHistory';
import { OrganizationData, OrganizationDataForUser, OrganizationUser, Quiz, RobotCustomAction, UserWithOrgData } from "src/globalUtils/API";
// import fetchRobotFAQs, { fetchRobotFAQsKey } from './fetchKnowledgeBase';
import { deleteKnowledgeBases } from './knowledgeBase/deleteKnowledgeBases';
import getOrganizationRobots, { getOrganizationRobotsKey } from './getOrganizationRobots';
import { ReducerStatus } from '../util';
import { fetchKnowledgeBases, fetchKnowledgeBasesKey } from './knowledgeBase/fetchKnowledgeBases';
import { createKnowledgeBase } from './knowledgeBase/createKnowledgeBase';
import { updateKnowledgeBase } from './knowledgeBase/updateKnowledgeBase';
import { updateRobotCustomAction } from './robotCustomAction/updateRobotCustomAction';
import { createRobotCustomActions } from './robotCustomAction/createRobotCustomActions';
import { deleteRobotCustomActions } from './robotCustomAction/deleteRobotCustomActions';
import { fetchRobotCustomActions, fetchRobotCustomActionsKey } from './robotCustomAction/fetchRobotCustomActions';
import { fetchGenAIAssistants, fetchGenAIAssistantsKey } from './genaiAssistant/fetchGenAIAssistants';
import { deleteGenAIAssistants } from './genaiAssistant/deleteGenAIAssistants';
import { createGenAIAssistant } from './genaiAssistant/createGenAIAssistant';
import { updateGenAIAssistant } from './genaiAssistant/updateGenAIAssistant';
import { updateConversationHistory } from 'src/globalUtils/graphql/mutations';
import { WritableDraft } from 'immer/dist/internal';
import fetchChatThreadGenAiMessagesByThreadId, { fetchChatThreadGenAiMessagesByThreadIdFetchKey } from './chatThread/fetchChatThreadGenAiMessagesByThreadId';
import { fetchMiniApps, fetchMiniAppsKey } from './miniApp/fetchMiniApps';
import { createLyzaAppMiniApp } from './miniApp/createLyzaAppMiniApp';
import { deleteLyzaAppMiniApp } from './miniApp/deleteLyzaAppMiniApp';
import { updateLyzaAppMiniApp } from './miniApp/updateLyzaAppMiniApp';
import { getKnowledgeBasesByIds } from './knowledgeBase/getKnowledgeBasesByIds';
import { triggerWebsiteKnowledgeBaseJobForFindingAllUrls } from './knowledgeBase/triggerWebsiteKnowledgeBaseJobForFindingAllUrls';
import { listWebsiteKnowledgeBaseUrlsByKnowledgeBaseId, listWebsiteKnowledgeBaseUrlsByKnowledgeBaseIdKey } from './knowledgeBase/website/listWebsiteKnowledgeBaseUrlsByKnowledgeBaseId';
import { updateWebsiteKnowledgeBaseUrlEnabledDecision } from './knowledgeBase/website/updateWebsiteKnowledgeBaseUrlEnabledDecision';
import { fetchQuizCatalogs, fetchQuizCatalogsKey } from './quizCatalog/fetchQuizCatalogs';
import { deleteQuizCatalogs } from './quizCatalog/deleteQuizCatalogs';
import { createQuizCatalog } from './quizCatalog/createQuizCatalog';
import { updateQuizCatalog } from './quizCatalog/updateQuizCatalog';
import { fetchQuizesByCatalogID, fetchQuizesByCatalogIDKey } from './quizCatalog/quiz/fetchQuizesByCatalogID';
import { deleteQuizes } from './quizCatalog/quiz/deleteQuizes';
import { kMaxLength } from 'buffer';
import { createQuizes } from './quizCatalog/quiz/createQuizes';
import { updateQuizes } from './quizCatalog/quiz/updateQuizes';

const initialState: RobotConciergeState = {
    perOrganizationStates: [],
    activeRobotCustomActionIdForModal: null
}

function updateFetchStatusHelper({ state, orgId, fetchKey, status }: { state: WritableDraft<RobotConciergeState>, fetchKey: string; orgId: string; status: ReducerStatus }) {
    const existingOrgState = state.perOrganizationStates.find(s => s.organizationID === orgId)
    if (existingOrgState == null) {
        const newState: PerOrganizationState = {
            organizationID: orgId,
            fetchStatus: {},
            robotCustomActionByActionId: {},
            websiteDetailsByKnowledgeBaseId: {},
            robotChatHistoryByRobotId: {},
            chatThreadDetailsByThreadId: {},
            quizCatalogs: [],
            quizesByCatalogID: {}
        }
        state.perOrganizationStates = [...state.perOrganizationStates, newState]
    }
    const updated: PerOrganizationState[] = state.perOrganizationStates.map(s =>
        s.organizationID !== orgId ? s : {
            ...s,
            fetchStatus: {
                ...s.fetchStatus,
                [fetchKey]: status,
            },
        }
    );
    state.perOrganizationStates = updated
}
export const robotConciergeSlice = createSlice({
    name: 'robotConcierge',
    initialState,
    reducers: {
        updateFetchStatus: (state, action: PayloadAction<{ fetchKey: string; orgId: string; status: ReducerStatus }>) => {
            updateFetchStatusHelper({ state, orgId: action.payload.orgId, status: action.payload.status, fetchKey: action.payload.fetchKey })
        },
        initAfterLogin: (state, action: PayloadAction<{ userWithOrgData: UserWithOrgData | undefined }>) => {
            const { userWithOrgData } = action.payload
            if (!userWithOrgData) {
                return
            }
            const existing = state.perOrganizationStates
            const orgIdsNotYetRecorded = userWithOrgData.organizations.filter(o => !existing.map(e => e.organizationID).includes(o.userOrg.organizationID))
            state.perOrganizationStates = [...existing, ...orgIdsNotYetRecorded.map(x => ({
                organizationID: x.userOrg.organizationID,
                robotChatHistoryByRobotId: {},
                chatThreadDetailsByThreadId: {},
                websiteDetailsByKnowledgeBaseId: {},
                robotCustomActionByActionId: {},
                fetchStatus: {},
                quizCatalogs: [],
                quizesByCatalogID: {}
            }))]
        },
        setActiveRobotCustomActionModal: (state, action: PayloadAction<{
            activeRobotCustomActionIdForModal: {
                actionId: string;
                knowledgeBaseId: string;
            } | undefined | null
        }>) => {
            const { activeRobotCustomActionIdForModal } = action.payload
            state.activeRobotCustomActionIdForModal = activeRobotCustomActionIdForModal
        },
    },
    extraReducers(builder) {
        // fetchConversationHistory
        builder.addCase(fetchConversationHistory.pending, (state, action) => {
        })
        builder.addCase(fetchConversationHistory.fulfilled, (state, action) => {
            const payload = action.payload
            // important - otherwise it will update state even if no value is changed
            if (payload === undefined) {
                return
            }

            // ------- V1 --------
            // const robotStatesByRobotId = state.perOrganizationStates.find(s => s.organizationID === payload.organizationID)!!.robotStatesByRobotId!!
            // // remove existing
            // const newDatesDataToAdd = payload.convoHistoriesToAdd.map(x => x.date)
            // const convos = (robotStatesByRobotId[payload.robotDeviceID].conversations ?? []).filter(c => !newDatesDataToAdd.includes(c.date))
            // // add new
            // const afterAppend = [...convos, ...payload.convoHistoriesToAdd]
            // // sort desc
            // const final = afterAppend.sort((a, b) => b.date.localeCompare(a.date))
            // robotStatesByRobotId = {
            //     ...robotStatesByRobotId,
            //     [payload.robotDeviceID]: {
            //         ...robotStatesByRobotId[payload.robotDeviceID],
            //         conversations: final
            //     }
            // }

            const orgState = state.perOrganizationStates.find(s => s.organizationID === payload.organizationID)!!
            if (orgState.robotStatesByRobotId == null) {
                orgState.robotStatesByRobotId = {}
            }
            const newDatesDataToAdd = payload.convoHistoriesToAdd.map(x => x.date);

            const existingConvos = (orgState.robotStatesByRobotId?.[payload.robotDeviceID]?.conversations ?? []).filter(
                c => !newDatesDataToAdd.includes(c.date)
            );

            const sortedConversations = [...existingConvos, ...payload.convoHistoriesToAdd].sort((a, b) =>
                b.date.localeCompare(a.date)
            );

            orgState.robotStatesByRobotId[payload.robotDeviceID] = {
                ...orgState.robotStatesByRobotId[payload.robotDeviceID],
                conversations: sortedConversations,
            };

            // ------- V2 --------
            // merge into existing
            orgState.robotChatHistoryByRobotId[payload.robotDeviceID] = {
                ...orgState.robotChatHistoryByRobotId[payload.robotDeviceID],
                ...payload.robotChatHistoriesV2ToMerge
            }

            updateFetchStatus({
                orgId: action.meta.arg.organizationID,
                fetchKey: fetchConversationHistoryKey(action.meta.arg.robotDeviceID),
                status: 'fulfilled'
            })

            // emit succcess
            enqueueSuccessSnackbarMessage('Successfully fetched robot conversation history')
        })
        builder.addCase(fetchConversationHistory.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to fetch robot conversation history')
            updateFetchStatus({
                orgId: action.meta.arg.organizationID,
                fetchKey: fetchConversationHistoryKey(action.meta.arg.robotDeviceID),
                status: 'rejected'
            })
        })

        // fetchKnowledgeBases
        builder.addCase(fetchKnowledgeBases.pending, (state, action) => {
        })
        builder.addCase(fetchKnowledgeBases.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }
            const existing = state.perOrganizationStates.find(s => s.organizationID === payload.organizationID)!!
            existing.knowledgeBases = payload.knowledgeBases
            updateFetchStatusHelper({ state, orgId: action.meta.arg.userOrg.userOrg.organizationID, fetchKey: fetchKnowledgeBasesKey, status: 'fulfilled' })
        })
        builder.addCase(fetchKnowledgeBases.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to fetch knowledge bases')
            updateFetchStatusHelper({ state, orgId: action.meta.arg.userOrg.userOrg.organizationID, fetchKey: fetchKnowledgeBasesKey, status: 'rejected' })
        })

        // listWebsiteKnowledgeBaseUrlsByKnowledgeBaseId
        builder.addCase(listWebsiteKnowledgeBaseUrlsByKnowledgeBaseId.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.meta.arg.userOrg.userOrg.organizationID)!!
            existing.websiteDetailsByKnowledgeBaseId[action.meta.arg.knowledgeBaseID] = payload.websiteKnowledgeBaseUrls
            updateFetchStatusHelper({ state, orgId: action.meta.arg.userOrg.userOrg.organizationID, fetchKey: listWebsiteKnowledgeBaseUrlsByKnowledgeBaseIdKey(action.meta.arg.knowledgeBaseID), status: 'fulfilled' })
        })
        builder.addCase(listWebsiteKnowledgeBaseUrlsByKnowledgeBaseId.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to fetch website knowledge base URLs')
            updateFetchStatusHelper({ state, orgId: action.meta.arg.userOrg.userOrg.organizationID, fetchKey: listWebsiteKnowledgeBaseUrlsByKnowledgeBaseIdKey(action.meta.arg.knowledgeBaseID), status: 'rejected' })
        })

        // getKnowledgeBasesByIds
        builder.addCase(getKnowledgeBasesByIds.fulfilled, (state, action) => {
            const { payload } = action
            const existing = state.perOrganizationStates.find(s => s.organizationID === payload.organizationID)!!
            // Merge and prioritize `new` items
            const knowledgeBaseMap = new Map(
                [...(existing.knowledgeBases || []), ...(payload.knowledgeBases || [])].map(item => [item.id, item])
            );

            existing.knowledgeBases = Array.from(knowledgeBaseMap.values());
        })

        // triggerWebsiteKnowledgeBaseJobForFindingAllUrls
        builder.addCase(triggerWebsiteKnowledgeBaseJobForFindingAllUrls.fulfilled, (state, action) => {
            const { payload } = action
            const existing = state.perOrganizationStates.find(s => s.organizationID === payload.orgId)!!
            // Merge and prioritize `new` items
            const knowledgeBaseMap = new Map(
                [...(existing.knowledgeBases || []), payload.updatedKnowledgeBase].map(item => [item.id, item])
            );

            existing.knowledgeBases = Array.from(knowledgeBaseMap.values());
        })
        builder.addCase(triggerWebsiteKnowledgeBaseJobForFindingAllUrls.rejected, (state, action) => {
            enqueueErrorSnackbarMessage(action.error.message ?? 'Faile to refresh all URLs for the Web domain.')
        })

        // updateWebsiteKnowledgeBaseUrlEnabledDecision
        builder.addCase(updateWebsiteKnowledgeBaseUrlEnabledDecision.fulfilled, (state, action) => {
            const existingOrg = state.perOrganizationStates.find(s => s.organizationID === action.payload.orgId)!!
            const exitsingUrls = existingOrg.websiteDetailsByKnowledgeBaseId[action.meta.arg.knowledgeBaseID]
            const updatedUrls = new Set(action.meta.arg.urls)
            existingOrg.websiteDetailsByKnowledgeBaseId[action.meta.arg.knowledgeBaseID] = exitsingUrls.map(item =>
                updatedUrls.has(item.url) ?
                    {
                        ...item,
                        isEnabledDecision: action.meta.arg.updatedEnabledDecision
                    }
                    : item
            )
            enqueueSuccessSnackbarMessage("Successfully updated decisions for the selected URLs.")
        })
        builder.addCase(updateWebsiteKnowledgeBaseUrlEnabledDecision.rejected, (state, action) => {
            enqueueErrorSnackbarMessage(action.error.message ?? 'Failed to update website URL decisions.')
        })

        // deleteKnowledgeBases
        builder.addCase(deleteKnowledgeBases.pending, (state, action) => {
        })
        builder.addCase(deleteKnowledgeBases.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)
            if (existing && existing.knowledgeBases) {
                existing.knowledgeBases = existing.knowledgeBases.filter(x => !action.payload.deletedKnowledgeBaseIDs.includes(x.id))
                // dont care the associated children. cannot be accessed anyway
            }

            enqueueSuccessSnackbarMessage('Successfully deleted knowledge bases')
        })
        builder.addCase(deleteKnowledgeBases.rejected, (state, action) => {
            enqueueErrorSnackbarMessage(action.error.message ?? 'Failed to delete knowledge bases')
        })

        // createKnowledgeBase
        builder.addCase(createKnowledgeBase.pending, (state, action) => {
        })
        builder.addCase(createKnowledgeBase.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)
            if (existing) {
                existing.knowledgeBases = [action.payload.createdKnowledgeBase, ...existing.knowledgeBases ?? []]
            }

            enqueueSuccessSnackbarMessage('Successfully created knowledge base')
        })
        builder.addCase(createKnowledgeBase.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to create knowledge base')
        })

        // updateKnowledgeBase
        builder.addCase(updateKnowledgeBase.pending, (state, action) => {
        })
        builder.addCase(updateKnowledgeBase.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)
            if (existing && existing.knowledgeBases) {
                existing.knowledgeBases = existing.knowledgeBases.map(k => k.id === action.payload.updatedKnowledgeBase.id ? action.payload.updatedKnowledgeBase : k)
            }

            enqueueSuccessSnackbarMessage('Successfully updated knowledge base')
        })
        builder.addCase(updateKnowledgeBase.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to update knowledge base')
        })

        // fetchRobotCustomActions
        builder.addCase(fetchRobotCustomActions.pending, (state, action) => {
        })
        builder.addCase(fetchRobotCustomActions.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }
            updateFetchStatusHelper({
                state,
                orgId: payload.organizationID,
                fetchKey: fetchRobotCustomActionsKey(payload.knowledgeBaseID),
                status: 'fulfilled'
            })
            const existing = state.perOrganizationStates.find(s => s.organizationID === payload.organizationID)!!
            if (!existing.robotCustomActionIdsByKnowledgeBaseId) {
                existing.robotCustomActionIdsByKnowledgeBaseId = {}
            }
            existing.robotCustomActionIdsByKnowledgeBaseId[payload.knowledgeBaseID] = payload.robotCustomActions.map(a => a.id)

            // update in sync
            const fetchedActionByIds: Record<string, RobotCustomAction> = payload.robotCustomActions.reduce(
                (acc, action) => {
                    acc[action.id] = action;
                    return acc;
                },
                {} as Record<string, RobotCustomAction>
            );
            existing.robotCustomActionByActionId = {
                ...existing.robotCustomActionByActionId,
                ...fetchedActionByIds
            }
        })
        builder.addCase(fetchRobotCustomActions.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to fetch custom actions')
            const { currentOrganizationID, knowledgeBaseID } = action.meta.arg
            state.perOrganizationStates.map(
                s => s.organizationID !== currentOrganizationID ? s
                    : {
                        ...s,
                        robotCustomActionsByKnowledgeBaseIdFetchStatus: {
                            [knowledgeBaseID]: 'rejected'
                        }
                    })
        })

        // deleteRobotCustomActions
        builder.addCase(deleteRobotCustomActions.pending, (state, action) => {
        })
        builder.addCase(deleteRobotCustomActions.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)?.robotCustomActionIdsByKnowledgeBaseId
            const { updatedKnowledgeBase } = action.payload
            if (existing?.[updatedKnowledgeBase.id]) {
                existing[updatedKnowledgeBase.id] = existing[updatedKnowledgeBase.id].filter(id => !action.payload.deletedRobotCustomActionIDs.includes(id))
            }

            const orgState = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)
            if (orgState) {
                orgState.robotCustomActionByActionId = Object.fromEntries(
                    Object.entries(orgState.robotCustomActionByActionId).filter(([key]) => !action.payload.deletedRobotCustomActionIDs.includes(key))
                );
            }

            // update knowledge base
            const existingOrgState = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)
            if (existingOrgState?.knowledgeBases) {
                existingOrgState.knowledgeBases = existingOrgState.knowledgeBases.map(e => e.id === updatedKnowledgeBase.id ? updatedKnowledgeBase : e)
            }

            enqueueSuccessSnackbarMessage('Successfully deleted custom actions')
        })
        builder.addCase(deleteRobotCustomActions.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to delete custom actions')
        })

        // createRobotCustomActions
        builder.addCase(createRobotCustomActions.pending, (state, action) => {
        })
        builder.addCase(createRobotCustomActions.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)?.robotCustomActionIdsByKnowledgeBaseId
            const { updatedKnowledgeBase } = action.payload
            if (existing?.[updatedKnowledgeBase.id]) {
                existing[updatedKnowledgeBase.id] = [...existing[updatedKnowledgeBase.id], ...action.payload.createdRobotCustomActions.map(a => a.id)]
            }

            const existingActions = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)?.robotCustomActionByActionId
            if (existingActions) {
                action.payload.createdRobotCustomActions.forEach(a => {
                    existingActions[a.id] = a
                })
            }

            // update knowledge base
            const existingOrgState = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)
            if (existingOrgState?.knowledgeBases) {
                existingOrgState.knowledgeBases = existingOrgState.knowledgeBases.map(e => e.id === updatedKnowledgeBase.id ? updatedKnowledgeBase : e)
            }

            enqueueSuccessSnackbarMessage('Successfully created custom actions')
        })
        builder.addCase(createRobotCustomActions.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to create custom actions')
        })

        // updateRobotCustomAction
        builder.addCase(updateRobotCustomAction.pending, (state, action) => {
        })
        builder.addCase(updateRobotCustomAction.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)?.robotCustomActionByActionId

            if (existing) {
                existing[action.payload.updatedRobotCustomAction.id] = action.payload.updatedRobotCustomAction
            }

            // update knowledge base
            const { updatedKnowledgeBase } = action.payload
            const existingOrgState = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)
            if (existingOrgState?.knowledgeBases) {
                existingOrgState.knowledgeBases = existingOrgState.knowledgeBases.map(e => e.id === updatedKnowledgeBase.id ? updatedKnowledgeBase : e)
            }

            enqueueSuccessSnackbarMessage('Successfully updated custom action')
        })
        builder.addCase(updateRobotCustomAction.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to update custom action')
        })

        // fetchGenAIAssistants
        builder.addCase(fetchGenAIAssistants.pending, (state, action) => {
        })
        builder.addCase(fetchGenAIAssistants.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }
            const existing = state.perOrganizationStates.find(s => s.organizationID === payload.organizationID)
            if (existing) {
                existing.genAIAssistants = payload.genAIAssistants
            }
            else {
                state.perOrganizationStates.push({
                    organizationID: payload.organizationID,
                    genAIAssistants: payload.genAIAssistants,
                    robotChatHistoryByRobotId: {},
                    chatThreadDetailsByThreadId: {},
                    websiteDetailsByKnowledgeBaseId: {},
                    robotCustomActionByActionId: {},
                    fetchStatus: {},
                    quizCatalogs: [],
                    quizesByCatalogID: {}
                })
            }
            updateFetchStatusHelper({ state, orgId: action.meta.arg.userOrg.userOrg.organizationID, fetchKey: fetchGenAIAssistantsKey, status: 'fulfilled' })
        })
        builder.addCase(fetchGenAIAssistants.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to fetch GenAI Assistants')
            updateFetchStatusHelper({ state, orgId: action.meta.arg.userOrg.userOrg.organizationID, fetchKey: fetchGenAIAssistantsKey, status: 'rejected' })
        })

        // deleteGenAIAssistants
        builder.addCase(deleteGenAIAssistants.pending, (state, action) => {
        })
        builder.addCase(deleteGenAIAssistants.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)
            if (existing && existing.genAIAssistants) {
                existing.genAIAssistants = existing.genAIAssistants.filter(x => !action.payload.deletedGenAIAssistantIDs.includes(x.id))
                existing.knowledgeBases = (existing.knowledgeBases ?? []).map(k => {
                    const updated = action.payload.updatedKnowledgeBases.find(k2 => k2.id === k.id)
                    if (updated != null) {
                        return updated
                    }
                    return k
                })
            }

            enqueueSuccessSnackbarMessage('Successfully deleted GenAI Assistants')
        })
        builder.addCase(deleteGenAIAssistants.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to delete GenAI Assistants')
        })

        // createGenAIAssistant
        builder.addCase(createGenAIAssistant.pending, (state, action) => {
        })
        builder.addCase(createGenAIAssistant.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)
            if (existing) {
                existing.genAIAssistants = [action.payload.createdGenAIAssistant, ...existing.genAIAssistants ?? []]
                existing.knowledgeBases = (existing.knowledgeBases ?? []).map(k => {
                    const updated = action.payload.updatedKnowledgeBases.find(k2 => k2.id === k.id)
                    if (updated != null) {
                        return updated
                    }
                    return k
                })
            }

            enqueueSuccessSnackbarMessage('Successfully created GenAI Assistant')
        })
        builder.addCase(createGenAIAssistant.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to create GenAI Assistant')
        })

        // updateGenAIAssistant
        builder.addCase(updateGenAIAssistant.pending, (state, action) => {
        })
        builder.addCase(updateGenAIAssistant.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)
            if (existing && existing.genAIAssistants) {
                existing.genAIAssistants = existing.genAIAssistants.map(k => k.id === action.payload.updatedGenAIAssistant.id ? action.payload.updatedGenAIAssistant : k)
                existing.knowledgeBases = (existing.knowledgeBases ?? []).map(k => {
                    const updated = action.payload.updatedKnowledgeBases.find(k2 => k2.id === k.id)
                    if (updated != null) {
                        return updated
                    }
                    return k
                })
            }

            enqueueSuccessSnackbarMessage('Successfully updated GenAI Assistant')
        })
        builder.addCase(updateGenAIAssistant.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to update GenAI Assistant')
        })

        // --------------------------------------------------------------------------------------------
        // fetchQuizCatalogs
        builder.addCase(fetchQuizCatalogs.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }
            const existing = state.perOrganizationStates.find(s => s.organizationID === payload.organizationID)!!
            existing.quizCatalogs = payload.quizCatalogs
            updateFetchStatusHelper({ state, orgId: action.meta.arg.userOrg.userOrg.organizationID, fetchKey: fetchQuizCatalogsKey, status: 'fulfilled' })
        })
        builder.addCase(fetchQuizCatalogs.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to fetch quiz catalogs')
            updateFetchStatusHelper({ state, orgId: action.meta.arg.userOrg.userOrg.organizationID, fetchKey: fetchQuizCatalogsKey, status: 'rejected' })
        })

        // deleteQuizCatalogs
        builder.addCase(deleteQuizCatalogs.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)!!
            existing.quizCatalogs = existing.quizCatalogs.filter(x => !action.payload.deletedQuizCatalogIDs.includes(x.id))
            existing.quizesByCatalogID = Object.fromEntries(
                Object.entries(existing.quizesByCatalogID).filter(([key]) => !action.payload.deletedQuizCatalogIDs.includes(key))
            );
            enqueueSuccessSnackbarMessage('Successfully deleted quiz catalogs')
        })
        builder.addCase(deleteQuizCatalogs.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to delete quiz catalogs')
        })

        // createQuizCatalog
        builder.addCase(createQuizCatalog.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)!!
            existing.quizCatalogs = [action.payload.createdQuizCatalog, ...existing.quizCatalogs ?? []]

            enqueueSuccessSnackbarMessage('Successfully created quiz catalog')
        })
        builder.addCase(createQuizCatalog.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to create quiz catalog')
        })

        // updateQuizCatalog
        builder.addCase(updateQuizCatalog.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)!!
            existing.quizCatalogs = [action.payload.updatedQuizCatalog, ...existing.quizCatalogs.filter(k => k.id !== action.payload.updatedQuizCatalog.id)]

            enqueueSuccessSnackbarMessage('Successfully updated quiz catalog')
        })
        builder.addCase(updateQuizCatalog.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to update quiz catalog')
        })


        // --------------------------------------------------------------------------------------------
        // fetchQuizesByCatalogID
        builder.addCase(fetchQuizesByCatalogID.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }
            const existing = state.perOrganizationStates.find(s => s.organizationID === payload.organizationID)!!
            existing.quizesByCatalogID[action.meta.arg.quizCatalogID] = payload.quizes
            updateFetchStatusHelper({ state, orgId: action.meta.arg.userOrg.userOrg.organizationID, fetchKey: fetchQuizesByCatalogIDKey(action.meta.arg.quizCatalogID), status: 'fulfilled' })
        })
        builder.addCase(fetchQuizesByCatalogID.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to fetch quizes')
            updateFetchStatusHelper({ state, orgId: action.meta.arg.userOrg.userOrg.organizationID, fetchKey: fetchQuizesByCatalogIDKey(action.meta.arg.quizCatalogID), status: 'rejected' })
        })

        // deleteQuizes
        builder.addCase(deleteQuizes.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)!!
            existing.quizesByCatalogID = Object.fromEntries(
                Object.entries(existing.quizesByCatalogID).map((entry) => entry[0] === action.meta.arg.variables.quizCatalogID
                    ? [entry[0], entry[1].filter(q => !action.payload.deletedQuizesIDs.includes(q.id))]
                    : entry
                ))
            enqueueSuccessSnackbarMessage('Successfully deleted quizes')
        })
        builder.addCase(deleteQuizes.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to delete quizes')
        })

        // createQuizes
        builder.addCase(createQuizes.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)!!
            existing.quizCatalogs = [action.payload.updatedQuizCatalog, ...existing.quizCatalogs.filter(c => c.id !== action.payload.updatedQuizCatalog.id)]
            existing.quizesByCatalogID[action.payload.updatedQuizCatalog.id] = [...action.payload.createdQuizes, ...existing.quizesByCatalogID[action.payload.updatedQuizCatalog.id]]
                .sort((a, b) => a.sequence - b.sequence)

            enqueueSuccessSnackbarMessage('Successfully created quizes')
        })
        builder.addCase(createQuizes.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to create quizes')
        })

        // updateQuizes
        builder.addCase(updateQuizes.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)!!
            existing.quizCatalogs = [action.payload.updatedQuizCatalog, ...existing.quizCatalogs.filter(c => c.id !== action.payload.updatedQuizCatalog.id)]

            const updatedQuizesById: Map<string, Quiz> = action.payload.updatedQuizes.reduce((acc, quiz) => {
                acc.set(quiz.id, quiz)
                return acc
            }, new Map())
            existing.quizesByCatalogID[action.payload.updatedQuizCatalog.id] = existing.quizesByCatalogID[action.payload.updatedQuizCatalog.id].map(x =>
                updatedQuizesById.get(x.id) ?? x
            )
                .sort((a, b) => a.sequence - b.sequence)

            enqueueSuccessSnackbarMessage('Successfully updated quizes')
        })
        builder.addCase(updateQuizes.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to update quizes')
        })

        // --------------------------------------------------------------------------------------------
        // getOrganizationRobots
        builder.addCase(getOrganizationRobots.pending, (state, action) => {
        })
        builder.addCase(getOrganizationRobots.fulfilled, (state, action) => {
            const payload = action.payload
            if (!payload) {
                return
            }
            // update existing
            state.perOrganizationStates = state.perOrganizationStates.map(s => s.organizationID === payload.organizationID
                ? {
                    ...s,
                    robotStatesByRobotId: payload.kebbiDevicesForOrganization.reduce<RobotStates>((acc, k) => {
                        acc[k.id] = {}; // Set the key as k.id with an empty object as the value
                        return acc;
                    }, {})
                }
                : s)
            // add new if existing doesn't exist
            if (state.perOrganizationStates.find(x => x.organizationID === payload.organizationID) == null) {
                state.perOrganizationStates.push({
                    organizationID: payload.organizationID,
                    robotStatesByRobotId: payload.kebbiDevicesForOrganization.reduce<RobotStates>((acc, k) => {
                        acc[k.id] = {}; // Set the key as k.id with an empty object as the value
                        return acc;
                    }, {}),
                    robotChatHistoryByRobotId: {},
                    chatThreadDetailsByThreadId: {},
                    robotCustomActionByActionId: {},
                    websiteDetailsByKnowledgeBaseId: {},
                    fetchStatus: {},
                    quizCatalogs: [],
                    quizesByCatalogID: {}
                })
            }
            updateFetchStatusHelper({ state, orgId: action.meta.arg.organizationID, fetchKey: getOrganizationRobotsKey, status: 'fulfilled' })
        })
        builder.addCase(getOrganizationRobots.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to load robots')
            updateFetchStatusHelper({ state, orgId: action.meta.arg.organizationID, fetchKey: getOrganizationRobotsKey, status: 'rejected' })
        })


        // fetchChatThreadGenAiMessagesByThreadId
        builder.addCase(fetchChatThreadGenAiMessagesByThreadId.pending, (state, action) => {
        })
        builder.addCase(fetchChatThreadGenAiMessagesByThreadId.fulfilled, (state, action) => {
            const payload = action.payload
            if (!payload) {
                return
            }
            // update existing
            state.perOrganizationStates = state.perOrganizationStates.map(s => s.organizationID === action.meta.arg.orgId
                ? {
                    ...s,
                    chatThreadDetailsByThreadId: {
                        ...s.chatThreadDetailsByThreadId,
                        [action.meta.arg.threadId]: {
                            genAIAssistantThreadMessagesSortedByTimestampDesc: payload.messages
                        }
                    }
                }
                : s)
            updateFetchStatusHelper({ state, orgId: action.meta.arg.orgId, fetchKey: fetchChatThreadGenAiMessagesByThreadIdFetchKey(action.meta.arg.threadId), status: 'fulfilled' })
        })
        builder.addCase(fetchChatThreadGenAiMessagesByThreadId.rejected, (state, action) => {
            console.error(action.error)
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to load GenAI Assistant messages for the chat')
            updateFetchStatusHelper({ state, orgId: action.meta.arg.orgId, fetchKey: fetchChatThreadGenAiMessagesByThreadIdFetchKey(action.meta.arg.threadId), status: 'rejected' })
        })

        // fetchMiniApps
        builder.addCase(fetchMiniApps.pending, (state, action) => {
        })
        builder.addCase(fetchMiniApps.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }
            const existing = state.perOrganizationStates.find(s => s.organizationID === payload.organizationID)!!
            existing.miniApps = payload.miniApps

            updateFetchStatusHelper({ state, orgId: action.meta.arg.userOrg.userOrg.organizationID, fetchKey: fetchMiniAppsKey, status: 'fulfilled' })
        })
        builder.addCase(fetchMiniApps.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to fetch Mini Apps')
            updateFetchStatusHelper({ state, orgId: action.meta.arg.userOrg.userOrg.organizationID, fetchKey: fetchMiniAppsKey, status: 'rejected' })
        })

        // createLyzaAppMiniApp
        builder.addCase(createLyzaAppMiniApp.pending, (state, action) => {
        })
        builder.addCase(createLyzaAppMiniApp.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }

            // update mini app
            const existing = state.perOrganizationStates.find(s => s.organizationID === payload.organizationID)!!
            existing.miniApps = [
                ...existing.miniApps ?? [],
                payload.createdLyzaAppMiniApp,
            ]
            enqueueSuccessSnackbarMessage('Successfully created Mini App')
        })
        builder.addCase(createLyzaAppMiniApp.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to create Mini App')
        })

        // updateLyzaAppMiniApp
        builder.addCase(updateLyzaAppMiniApp.pending, (state, action) => {
        })
        builder.addCase(updateLyzaAppMiniApp.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }

            // update mini app
            const existing = state.perOrganizationStates.find(s => s.organizationID === payload.organizationID)!!
            existing.miniApps = (existing.miniApps ?? []).map(x => x.codelabProjectID === payload.updatedLyzaAppMiniApp.codelabProjectID ? payload.updatedLyzaAppMiniApp : x)

            enqueueSuccessSnackbarMessage('Successfully updated Mini App')
        })
        builder.addCase(updateLyzaAppMiniApp.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to update Mini App')
        })

        // deleteLyzaAppMiniApps
        builder.addCase(deleteLyzaAppMiniApp.pending, (state, action) => {
        })
        builder.addCase(deleteLyzaAppMiniApp.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }

            // update mini app
            const existing = state.perOrganizationStates.find(s => s.organizationID === payload.organizationID)!!
            existing.miniApps = (existing.miniApps ?? []).filter(x => x.codelabProjectID !== payload.deletedLyzaAppMiniApp.codelabProjectID)

            enqueueSuccessSnackbarMessage('Successfully deleted Mini App')
        })
        builder.addCase(deleteLyzaAppMiniApp.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to delete Mini App')
        })
    }
})

export const { updateFetchStatus, initAfterLogin, setActiveRobotCustomActionModal } = robotConciergeSlice.actions

export default robotConciergeSlice.reducer