import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { KnowledgeBase, ListKnowledgeBasesByOwnerIDQuery, ListKnowledgeBasesByOwnerIDQueryVariables, ListWebsiteKnowledgeBaseUrlsByKnowledgeBaseIdQuery, ListWebsiteKnowledgeBaseUrlsByKnowledgeBaseIdQueryVariables, OrganizationDataForUser, OrgRole, OwnerType, WebsiteKnowledgeBaseUrl } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from 'src/globalUtils/graphql/queries';
import { RootState, AppDispatch, store } from '../../../store'
import { graphQLClient } from '../../../util'
import { getCurrentSelectedOrganization } from '../../../authentication/selectedOrganizationHook';
import { updateFetchStatus } from '../../robotConciergeSlice';

export const listWebsiteKnowledgeBaseUrlsByKnowledgeBaseIdKey = (kId: string) => `listWebsiteKnowledgeBaseUrlsByKnowledgeBaseId-${kId}`
export const listWebsiteKnowledgeBaseUrlsByKnowledgeBaseId = createAsyncThunk<
    {
        websiteKnowledgeBaseUrls: WebsiteKnowledgeBaseUrl[]
    } | undefined, // output
    {
        userOrg: OrganizationDataForUser;
        knowledgeBaseID: string;
        forceRefetch: boolean;
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/listWebsiteKnowledgeBaseUrlsByKnowledgeBaseId', async ({ forceRefetch, userOrg, knowledgeBaseID }, { dispatch, getState }) => {
    const orgState = getState().robotConcierge.perOrganizationStates.find(x => x.organizationID === userOrg.userOrg.organizationID)

    const fetchStatus = orgState?.fetchStatus[listWebsiteKnowledgeBaseUrlsByKnowledgeBaseIdKey(knowledgeBaseID)]
    if ((!forceRefetch && fetchStatus === 'fulfilled') || fetchStatus === 'pending') {
        return undefined;
    }

    dispatch(updateFetchStatus({
        fetchKey: listWebsiteKnowledgeBaseUrlsByKnowledgeBaseIdKey(knowledgeBaseID),
        orgId: userOrg.userOrg.organizationID,
        status: 'pending'
    }))

    let shouldFetch = true
    let nextToken = null
    let websiteKnowledgeBaseUrls: WebsiteKnowledgeBaseUrl[] = []

    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        // const user = await Auth.currentAuthenticatedUser() as CognitoUser
        const variables: ListWebsiteKnowledgeBaseUrlsByKnowledgeBaseIdQueryVariables = {
            knowledgeBaseId: knowledgeBaseID,
            nextToken
        }
        const response: GraphQLResult<ListWebsiteKnowledgeBaseUrlsByKnowledgeBaseIdQuery> = await graphQLClient.graphql<GraphQLQuery<ListWebsiteKnowledgeBaseUrlsByKnowledgeBaseIdQuery>>({
            query: queries.listWebsiteKnowledgeBaseUrlsByKnowledgeBaseId,
            variables
        });
        nextToken = response.data?.listWebsiteKnowledgeBaseUrlsByKnowledgeBaseId?.nextToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        websiteKnowledgeBaseUrls = websiteKnowledgeBaseUrls.concat(response.data!!.listWebsiteKnowledgeBaseUrlsByKnowledgeBaseId!!.items!! as WebsiteKnowledgeBaseUrl[])
    }

    return {
        websiteKnowledgeBaseUrls
    }
})
