/* eslint-disable perfectionist/sort-imports */
import 'src/global.css';

// ----------------------------------------------------------------------

import Router from './routes';

import { useScrollToTop } from './hooks/use-scroll-to-top';

import { ThemeProvider } from './theme/theme-provider';
import { LocalizationProvider } from './locales';
import { I18nProvider } from 'src/locales/i18n-provider';

import ProgressBar from './components/progress-bar';
import { MotionLazy } from './components/animate/motion-lazy';
import SnackbarProvider from './components/snackbar/snackbar-provider';
import { SettingsDrawer, SettingsProvider } from './components/settings';

import { AuthProvider } from './auth/context/amplify';
import { defaultFont } from './theme/core/typography';
import { OverlaysUI } from './sections/common/overlays/OverlaysUI';

// ----------------------------------------------------------------------

export default function App() {
  const charAt = `
    Welcome to Lyza Education!
  `;

  console.info(`%c${charAt}`, 'color: #5BE49B');

  useScrollToTop();

  return (
    <AuthProvider>
      <I18nProvider>
        <LocalizationProvider>
          <SettingsProvider
            settings={{
              colorScheme: 'light', // 'light' | 'dark'
              direction: 'ltr',
              contrast: 'default', // 'default' | 'hight'
              navLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
              primaryColor: 'cyan', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red';
              navColor: 'integrate',
              compactLayout: true,
              fontFamily: defaultFont,
            }}
          >
            <ThemeProvider>
              <MotionLazy>
                <SnackbarProvider>
                  <SettingsDrawer />
                  <ProgressBar />
                  <Router />
                  <OverlaysUI />
                </SnackbarProvider>
              </MotionLazy>
            </ThemeProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </I18nProvider>
    </AuthProvider>
  );
}
