import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../../store"
import { QuizCatalog, UpdateQuizCatalogMutation, UpdateQuizCatalogMutationVariables } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from "aws-amplify/api"
import * as mutations from 'src/globalUtils/graphql/mutations';
import { graphQLClient } from "../../util";

export const updateQuizCatalog = createAsyncThunk<
    {
        organizationID: string,
        updatedQuizCatalog: QuizCatalog,
    }, // output
    {
        updateQuizCatalogVariables: UpdateQuizCatalogMutationVariables
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/updateQuizCatalog', async ({ updateQuizCatalogVariables }, { dispatch, getState }) => {
    const { currentSelectedOrganizationID } = getState().authentication
    const response: GraphQLResult<UpdateQuizCatalogMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateQuizCatalogMutation>>({
        query: mutations.updateQuizCatalog,
        variables: updateQuizCatalogVariables
    });
    if (response.errors) {
        throw new Error("Failed to update quiz catalog")
    }
    return {
        organizationID: currentSelectedOrganizationID!!,
        updatedQuizCatalog: response.data.updateQuizCatalog
    }
})