import { Amplify } from 'aws-amplify';
import { useMemo, useEffect, useReducer, useCallback } from 'react';
import {
  signIn,
  signUp,
  signOut,
  confirmSignUp,
  resetPassword,
  getCurrentUser,
  resendSignUpCode,
  fetchAuthSession,
  fetchUserAttributes,
  confirmResetPassword,
} from '@aws-amplify/auth';

import * as mutations from 'src/globalUtils/graphql/mutations';
import { AuthContext } from './auth-context';
import { AuthUserType, ActionMapType, AuthStateType } from '../../types';
import * as AuthSlice from 'src/store/authentication/authenticationSlice';
import { store, RootState, RESET_APP } from '../../../store/store'
import { AuthSession } from 'aws-amplify/auth';
import { useRouter } from 'src/routes/hooks';
import { graphQLClient } from 'src/store/util';
import { GraphQLQuery } from 'aws-amplify/api';
import { GetCredentialsFromAuthTokenQueryVariables, GetCredentialsFromAuthTokenResponse } from "src/globalUtils/API";
import awsmobile from 'src/aws-exports';
import { enqueueErrorSnackbarMessage } from 'src/store/notifications/snackbar-notification-util';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------
/**
 * NOTE:
 * We only build demo at basic level.
 * Customer will need to do some extra handling yourself if you want to extend the logic and other features...
 */
// ----------------------------------------------------------------------

// /**
//  * DOCS: https://docs.amplify.aws/react/build-a-backend/auth/manage-user-session/
//  */
// Amplify.configure({
//   Auth: {
//     Cognito: {
//       userPoolId: `${AMPLIFY_API.userPoolId}`,
//       userPoolClientId: `${AMPLIFY_API.userPoolWebClientId}`,
//     },
//   },
// });

enum Types {
  INITIAL = 'INITIAL',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type Action = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: Action) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

type FetchAuthSessionAndUpdateStoreProps = {
  userNameOrEmail: string,
  password: string | undefined,
  legalFullName: string | undefined,
  preferredName: string | undefined,
  email: string | undefined
}

const fetchAuthSessionAndUpdateStore = async ({ userNameOrEmail, password, legalFullName, preferredName, email }: FetchAuthSessionAndUpdateStoreProps): Promise<AuthSession> => {
  const authSession = await fetchAuthSession()
  // important!!! so that user data is prepared first before proceeding
  await store.dispatch(AuthSlice.appInit({
    authSession,
    username: userNameOrEmail,
    password,
    legalFullName,
    preferredName,
    email,
  }))
  return authSession
}

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const router = useRouter()

  const initialize = useCallback(async () => {
    try {
      const { userId: currentUser, username } = await getCurrentUser();

      const userAttributes = await fetchUserAttributes();

      const authSession = await fetchAuthSessionAndUpdateStore({ userNameOrEmail: username, password: undefined, legalFullName: userAttributes.legal_full_name, preferredName: userAttributes.preferred_name, email: userAttributes.email })
      const { idToken, accessToken } = authSession.tokens ?? {};

      if (currentUser) {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: {
              ...userAttributes,
              id: userAttributes.sub,
              displayName: `${userAttributes.given_name} ${userAttributes.family_name}`,
              idToken,
              accessToken,
              role: 'admin',
            },
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (userNameOrEmail: string, password: string) => {
    const signInOutput = await signIn({
      username: userNameOrEmail,
      password,
    });

    const userAttributes = await fetchUserAttributes();

    const authSession = await fetchAuthSessionAndUpdateStore({ userNameOrEmail, password, legalFullName: userAttributes.legal_full_name, preferredName: userAttributes.preferred_name, email: userAttributes.email })
    const { idToken, accessToken } = authSession.tokens ?? {};

    dispatch({
      type: Types.INITIAL,
      payload: {
        user: {
          ...userAttributes,
          id: userAttributes.sub,
          displayName: `${userAttributes.given_name} ${userAttributes.family_name}`,
          idToken,
          accessToken,
          role: 'admin',
        },
      },
    });
  }, []);

  // REGISTER with USER NAME
  const registerWithUsername = useCallback(
    async (username: string, password: string, givenName: string, familyName: string) => {
      await signUp({
        username,
        password,
        options: {
          userAttributes: {
            given_name: givenName,
            family_name: familyName,
          },
        },
      });
    },
    []
  );

  // REGISTER with EMAIL
  const registerWithEmail = useCallback(
    async (email: string, password: string, givenName: string, familyName: string) => {
      await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
            given_name: givenName,
            family_name: familyName,
          },
        },
      });
    },
    []
  );

  // CONFIRM REGISTER
  const confirmRegister = useCallback(async (email: string, code: string) => {
    await confirmSignUp({
      username: email,
      confirmationCode: code,
    });
  }, []);

  // RESEND CODE REGISTER
  const resendCodeRegister = useCallback(async (email: string) => {
    await resendSignUpCode({
      username: email,
    });
  }, []);

  // LOGOUT
  const logoutHelper = useCallback(async (shouldRouteToLoginPage: boolean) => {
    dispatch({
      type: Types.LOGOUT,
    })
    await signOut();
    if (shouldRouteToLoginPage) {
      router.replace(paths.auth.amplify.login);
    }
    store.dispatch(AuthSlice.logout())
    const resetStore = () => ({
      type: RESET_APP,
    })
    store.dispatch(resetStore())
  }, [router]);

  // LOGOUT
  const logout = useCallback(async () => {
    await logoutHelper(true)
  }, [logoutHelper]);

  const loginUsingAuthToken = useCallback(async (tokenId: string) => {

    const response = await fetch(`${awsmobile.aws_cloud_logic_custom.find(x => x.name === 'getCredentialsFromToken')!!.endpoint}/auth/getCredentialsFromToken/${tokenId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      console.error(response)
      enqueueErrorSnackbarMessage("Failed to load - please login")
      return false
    }

    const body: GetCredentialsFromAuthTokenResponse = await response.json()
    if (body.errorMessage) {
      enqueueErrorSnackbarMessage(`Error: ${body.errorMessage}`)
      return false
    }

    const { username, pwd } = body
    await login(username!!, pwd!!)
    return true
  }, [login])

  // switchUserWithAuthToken
  const switchUserWithAuthToken = useCallback(async (tokenId: string) => {
    await logoutHelper(false)
    const result = await loginUsingAuthToken(tokenId)
    return result
  }, [loginUsingAuthToken, logoutHelper]);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email: string) => {
    await resetPassword({ username: email });
  }, []);

  // NEW PASSWORD
  const newPassword = useCallback(async (email: string, code: string, password: string) => {
    await confirmResetPassword({
      username: email,
      confirmationCode: code,
      newPassword: password,
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'amplify',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      loginUsingAuthToken,
      switchUserWithAuthToken,
      logout,
      registerWithUsername,
      registerWithEmail,
      newPassword,
      forgotPassword,
      confirmRegister,
      resendCodeRegister,
    }),
    [
      status,
      state.user,
      //
      login,
      loginUsingAuthToken,
      switchUserWithAuthToken,
      logout,
      registerWithUsername,
      registerWithEmail,
      newPassword,
      forgotPassword,
      confirmRegister,
      resendCodeRegister,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
