import { push } from '@lagunovsky/redux-react-router'
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ConversationHistory, GenAiMessageRole, ListChatThreadsByThreadSecondaryIDAndOwnerIdQuery, ListChatThreadsByThreadSecondaryIDAndOwnerIdQueryVariables, ListConversationHistoryByDeviceIdAndDateRangeInclusiveQuery, ListConversationHistoryByDeviceIdAndDateRangeInclusiveQueryVariables, Role } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from 'src/globalUtils/graphql/queries';
import { RootState, AppDispatch, store } from '../store'
import { getLocalDateStringFromDate, getLocalDateStringFromString, getNextDayString, getPreviousDayString } from './util';
import { graphQLClient } from '../util'
import { DailyConversationHistory, RobotDailyChatHistory } from './types';
import { ANONYMOUS_GUEST, IChatConversation } from 'src/types/chat';
import { updateFetchStatus } from './robotConciergeSlice';

type FetchConversationHistoryProps = {
    organizationID: string,
    robotDeviceID: string,
    startDate: string,
    endDate: string
}

export const fetchConversationHistoryKey = (robotId: string) => `fetchConversationHistoryKey-${robotId}`

function groupAndSortByDate(conversations: ConversationHistory[]): DailyConversationHistory[] {
    const groupedByDate: { [date: string]: ConversationHistory[] } = {};

    conversations.forEach(convo => {
        const localDate = getLocalDateStringFromString(convo.startDateTime);
        if (!groupedByDate[localDate]) {
            groupedByDate[localDate] = [];
        }
        groupedByDate[localDate].push(convo);
    });

    return Object.keys(groupedByDate).map(date => ({
        date,
        dailyConversationsSortedDesc: groupedByDate[date].sort((a, b) => new Date(b.startDateTime).getTime() - new Date(a.startDateTime).getTime())
    }));
}

function groupAndSortByDateV2(conversations: IChatConversation[]): RobotDailyChatHistory {
    const groupedByDate: RobotDailyChatHistory = {};

    conversations.forEach(convo => {
        // Get the last message date
        const lastMessage = convo.messages[convo.messages.length - 1];
        if (!lastMessage) return; // Skip if no messages in the conversation

        // Get local date string
        const localDate = getLocalDateStringFromString(lastMessage.createdAt);

        // Initialize the array for this date if it doesn't exist
        if (!groupedByDate[localDate]) {
            groupedByDate[localDate] = [];
        }

        // Add the conversation to the group
        groupedByDate[localDate].push(convo);
    });

    // Sort conversations within each date group by last message date (descending)
    Object.keys(groupedByDate).forEach(date => {
        groupedByDate[date].sort((a, b) => {
            const lastMessageA = a.messages.length > 0 ? a.messages[a.messages.length - 1] : null;
            const lastMessageACreatedDateTime = lastMessageA?.createdAt ?? ''
            const lastMessageB = b.messages.length > 0 ? b.messages[b.messages.length - 1] : null;
            const lastMessageBCreatedDateTime = lastMessageB?.createdAt ?? ''
            return lastMessageBCreatedDateTime.localeCompare(lastMessageACreatedDateTime);
        });
    });

    return groupedByDate;
}

function fillMissingDates(conversations: DailyConversationHistory[], start: string, end: string): DailyConversationHistory[] {
    const dateSet = new Set(conversations.map(convo => convo.date));
    const result: DailyConversationHistory[] = [...conversations];
    const endDate = new Date(end)
    for (let date = new Date(start); date <= endDate; date.setDate(date.getDate() + 1)) {
        const dateString = date.toISOString().split('T')[0];
        if (!dateSet.has(dateString)) {
            result.push({
                date: dateString,
                dailyConversationsSortedDesc: []
            });
        }
    }

    return result.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
}

function fillMissingDatesV2(
    conversations: RobotDailyChatHistory,
    start: string,
    end: string
): RobotDailyChatHistory {
    const result: RobotDailyChatHistory = { ...conversations }; // Clone the input object
    const endDate = new Date(end);

    // Generate all dates between start and end
    for (let date = new Date(start); date <= endDate; date.setDate(date.getDate() + 1)) {
        const dateString = date.toISOString().split('T')[0];
        if (!result[dateString]) {
            result[dateString] = []; // Fill missing date with an empty array
        }
    }

    return result;
}

function generateDateRangesToFetch(
    adjustedFetchStartDate: string,
    adjustedFetchEndDate: string,
    earliestFetchedDate: string,
    latestFetchedDate: string
): string[][] {
    const dateRangesToFetch: string[][] = [];
    if (adjustedFetchStartDate >= earliestFetchedDate && adjustedFetchEndDate <= latestFetchedDate) {
        // Do nothing, all data is already fetched
    } else if (adjustedFetchStartDate > latestFetchedDate) {
        dateRangesToFetch.push([adjustedFetchStartDate, adjustedFetchEndDate]);
    } else if (adjustedFetchEndDate < earliestFetchedDate) {
        dateRangesToFetch.push([adjustedFetchStartDate, adjustedFetchEndDate]);
    } else {
        if (adjustedFetchStartDate < earliestFetchedDate) {
            dateRangesToFetch.push([adjustedFetchStartDate, getPreviousDayString(earliestFetchedDate)]);
        }
        if (adjustedFetchEndDate > latestFetchedDate) {
            dateRangesToFetch.push([getNextDayString(latestFetchedDate), adjustedFetchEndDate]);
        }
    }
    return dateRangesToFetch;
}

export const fetchConversationHistory = createAsyncThunk<
    {
        organizationID: string,
        robotDeviceID: string,
        convoHistoriesToAdd: DailyConversationHistory[],
        robotChatHistoriesV2ToMerge: RobotDailyChatHistory,
        startDate: string,
        endDate: string,
    } | undefined,
    FetchConversationHistoryProps, // input argument - projectName
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/fetchConversationHistory', async ({ organizationID, robotDeviceID, startDate, endDate }: FetchConversationHistoryProps, { dispatch, getState }) => {
    const robotOrgState = getState().robotConcierge.perOrganizationStates.find(o => o.organizationID === organizationID)
    if (robotOrgState == null || robotOrgState.robotStatesByRobotId?.[robotDeviceID] == null) {
        return undefined;
    }
    const fetchStatus = robotOrgState.fetchStatus[fetchConversationHistoryKey(robotDeviceID)]
    if (fetchStatus === 'pending' || fetchStatus === 'fulfilled') {
        return undefined;
    }
    // // Adjust input date range if data already fetched
    const existingRobotHistory = robotOrgState.robotStatesByRobotId!![robotDeviceID];
    // const adjustedFetchStartDate = getLocalDateStringFromDate(startDate);
    // const adjustedFetchEndDate = getLocalDateStringFromDate(endDate);
    // if already fetched - skip
    if (existingRobotHistory?.conversations) {
        return undefined
    }

    dispatch(updateFetchStatus({
        fetchKey: fetchConversationHistoryKey(robotDeviceID),
        orgId: organizationID,
        status: 'pending'
    }))

    // let dateRangesToFetch: string[][] = [[adjustedFetchStartDate, adjustedFetchEndDate]]
    // if (existingRobotHistory && existingRobotHistory.conversations.length > 0) {
    //     const latestFetchedDate = existingRobotHistory.conversations[0].date;
    //     const earliestFetchedDate = existingRobotHistory.conversations[existingRobotHistory.conversations.length - 1].date;
    //     dateRangesToFetch = generateDateRangesToFetch(adjustedFetchStartDate, adjustedFetchEndDate, earliestFetchedDate, latestFetchedDate)
    // }

    let shouldFetch = true
    let nextToken = null
    let convoHistories: ConversationHistory[] = []

    // temporary hack to always fetch last 30 days data
    const hackEndDate = new Date();
    hackEndDate.setDate(hackEndDate.getDate() + 1);

    const hackStartDate = new Date();
    hackStartDate.setDate(hackStartDate.getDate() - 31);

    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        const variables: ListConversationHistoryByDeviceIdAndDateRangeInclusiveQueryVariables = {
            deviceID: robotDeviceID,
            startDate: getLocalDateStringFromDate(hackStartDate),
            endDate: getLocalDateStringFromDate(hackEndDate),
            nextToken
        }
        // const user = await Auth.currentAuthenticatedUser() as CognitoUser
        const response: GraphQLResult<ListConversationHistoryByDeviceIdAndDateRangeInclusiveQuery> = await graphQLClient.graphql<GraphQLQuery<ListConversationHistoryByDeviceIdAndDateRangeInclusiveQuery>>({
            query: queries.listConversationHistoryByDeviceIdAndDateRangeInclusive,
            variables
        });
        nextToken = response.data?.listConversationHistoryByDeviceIdAndDateRangeInclusive?.nextToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        convoHistories = convoHistories.concat(response.data!!.listConversationHistoryByDeviceIdAndDateRangeInclusive!!.items!! as ConversationHistory[])
    }
    /* eslint-enable no-await-in-loop */
    const groupedAndSorted = groupAndSortByDate(convoHistories);
    const dailyConversationsSortedDesc = fillMissingDates(groupedAndSorted, startDate, endDate);

    /* eslint-disable no-await-in-loop */
    shouldFetch = true;
    nextToken = null;
    let robotChatHistoriesV2: IChatConversation[] = []
    while (shouldFetch) {
        const variables: ListChatThreadsByThreadSecondaryIDAndOwnerIdQueryVariables = {
            threadSecondaryId: robotDeviceID.toLowerCase(),
            // TODO also fetch for individuals
            ownerID: organizationID,
            shouldIncludeThreadLastMessage: true,
            startDate: getLocalDateStringFromDate(hackStartDate),
            endDate: getLocalDateStringFromDate(hackEndDate),
            nextToken
        }
        // const user = await Auth.currentAuthenticatedUser() as CognitoUser
        const response: GraphQLResult<ListChatThreadsByThreadSecondaryIDAndOwnerIdQuery> = await graphQLClient.graphql<GraphQLQuery<ListChatThreadsByThreadSecondaryIDAndOwnerIdQuery>>({
            query: queries.listChatThreadsByThreadSecondaryIDAndOwnerId,
            variables
        });
        nextToken = response.data.listChatThreadsByThreadSecondaryIDAndOwnerId.nextThreadToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        const genAiThreadLatestMessageByThreadIdMap = new Map(response.data.listChatThreadsByThreadSecondaryIDAndOwnerId.genAiThreadsLatestMessages.map((message) => [message.threadID, message]))
        robotChatHistoriesV2 = robotChatHistoriesV2.concat(response.data.listChatThreadsByThreadSecondaryIDAndOwnerId.threads.map(t => ({
            id: t.threadID,
            unreadCount: 0,
            messages: [genAiThreadLatestMessageByThreadIdMap.get(t.threadID)].filter(x => x != null).map(msg => ({
                type: 'GenAIAssistantThreadMessage',
                robotCustomActionMatchResult: msg!!.robotCustomActionMatchResult,
                id: msg!!.messageID,
                createdAt: msg!!.createdAt,
                senderId: msg!!.role === GenAiMessageRole.SYSTEM ? robotDeviceID : ANONYMOUS_GUEST,
                textBody: msg!!.messageText ?? '',
                // contentType?: 'image';
                attachments: [],
                status: 'delivered'
            })),
            meParticipants: [
                {
                    id: robotDeviceID,
                    name: 'Kebbi',
                    role: Role.KEBBI_GPT,
                    email: '',
                    address: '',
                    avatarUrl: '',
                    phoneNumber: '',
                    lastActivityIsoDateTime: new Date().toISOString(),
                    status: 'online'
                }
            ],
            otherParticipants: [
                {
                    id: ANONYMOUS_GUEST,
                    name: 'Guest',
                    role: Role.USER,
                    email: '',
                    address: '',
                    avatarUrl: '',
                    phoneNumber: '',
                    lastActivityIsoDateTime: new Date().toISOString(),
                    status: 'offline'
                }
            ]
        })) as IChatConversation[])
    }
    /* eslint-enable no-await-in-loop */
    const groupedAndSortedV2 = groupAndSortByDateV2(robotChatHistoriesV2);
    const dailyConversationsSortedDescV2 = fillMissingDatesV2(groupedAndSortedV2, startDate, endDate);

    return {
        organizationID,
        robotDeviceID,
        convoHistoriesToAdd: dailyConversationsSortedDesc,
        robotChatHistoriesV2ToMerge: dailyConversationsSortedDescV2,
        startDate,
        endDate
    }
})

export default fetchConversationHistory