import { GraphQLQuery } from "aws-amplify/api";
import { KnowledgeBase, TriggerWebsiteKnowledgeBaseJobForFindingAllUrlsMutation, TriggerWebsiteKnowledgeBaseJobForFindingAllUrlsMutationVariables, TriggerWebsiteKnowledgeBaseJobForFindingAllUrlsOutput } from "src/globalUtils/API";
import { enqueueErrorSnackbarMessage } from "src/store/notifications/snackbar-notification-util";
import { graphQLClient } from "src/store/util";
import * as mutations from 'src/globalUtils/graphql/mutations';
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "src/store/store";

export const triggerWebsiteKnowledgeBaseJobForFindingAllUrls = createAsyncThunk<
    {
        orgId: string;
        updatedKnowledgeBase: KnowledgeBase
    }, // output
    {
        variables: TriggerWebsiteKnowledgeBaseJobForFindingAllUrlsMutationVariables
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/triggerWebsiteKnowledgeBaseJobForFindingAllUrls', async ({ variables }, { dispatch, getState }) => {

    const orgId = getState().authentication.currentSelectedOrganizationID!!
    const response = await graphQLClient.graphql<GraphQLQuery<TriggerWebsiteKnowledgeBaseJobForFindingAllUrlsMutation>>({
        query: mutations.triggerWebsiteKnowledgeBaseJobForFindingAllUrls,
        variables
    })

    const data = response.data.triggerWebsiteKnowledgeBaseJobForFindingAllUrls
    if (response.errors || data == null) {
        throw new Error('Failed to process')
    }

    if (data.errorMessage) {
        throw new Error(data.errorMessage)
    }

    return {
        orgId,
        updatedKnowledgeBase: data.updatedKnowledgeBase!!
    }
})
