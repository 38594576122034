import { saveProjectWorkspace } from '../../../store/blocklyProjects/blocklyProjectsSlice'
import { store, RootState } from '../../../store/store'
import { useSelector } from 'react-redux'
import NavBarButton from '../../../ui/navBar/NavBarButton'

export default function SaveCurrentProjectButton() {
    const currentWorkspace = useSelector((state: RootState) => state.blocklyProjects.currentWorkspace)
    return (
        <>
            {currentWorkspace && <NavBarButton onClick={() => store.dispatch(saveProjectWorkspace())} text={"Save Project"} icon="save" />}
        </>
    );
}