import React, { useState, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { CloseIcon } from 'yet-another-react-lightbox';
import { store, RootState } from '../../../store/store';
import { copyBlocklyProjectsByID } from '../../../store/blocklyProjects/blocklyProjectsSlice';
import NavBarButton from '../../../ui/navBar/NavBarButton';
import { AppConstants } from '../../../ui/Constants';
import { CopyBlocklyProjectButtonDialog } from './CopyBlocklyProjectButtonDialog';
import { useBoolean } from 'src/hooks/use-boolean';

export enum CopyBlocklyProjectButtonType {
    NAVBAR,
    PROJECT_LIST_BOTTOM
}

interface CopyBlocklyProjectButtonProps {
    blocklyProjectId: string;
    buttonType: CopyBlocklyProjectButtonType;
}

export default function CopyBlocklyProjectButton({ buttonType, blocklyProjectId }: CopyBlocklyProjectButtonProps) {
    const modalShow = useBoolean()
    const buttonText = 'Copy';
    const button = <NavBarButton onClick={() => modalShow.onTrue()} text={buttonText} icon={"copy"} />;

    return (
        <>
            {button}
            <CopyBlocklyProjectButtonDialog modalShow={modalShow.value} onHide={() => modalShow.onFalse()} blocklyProjectId={blocklyProjectId} />
        </>
    );
}
