import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { KnowledgeBase, ListKnowledgeBasesByOwnerIDQuery, ListKnowledgeBasesByOwnerIDQueryVariables, ListLyzaAppMiniAppsByOrgIDQuery, ListLyzaAppMiniAppsByOrgIDQueryVariables, LyzaAppMiniApp, OrganizationDataForUser, OrgRole, OwnerType } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from 'src/globalUtils/graphql/queries';
import { RootState, AppDispatch, store } from '../../store'
import { graphQLClient } from '../../util'
import { getCurrentSelectedOrganization } from '../../authentication/selectedOrganizationHook';
import { updateFetchStatus } from '../robotConciergeSlice';

export const fetchMiniAppsKey = 'fetchMiniApps'
export const fetchMiniApps = createAsyncThunk<
    {
        organizationID: string,
        miniApps: LyzaAppMiniApp[]
    } | undefined, // output
    {
        userOrg: OrganizationDataForUser
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/fetchMiniApps', async ({ userOrg }, { dispatch, getState }) => {
    const currentOrganizationID = userOrg.userOrg.organizationID
    const organizationState = getState().robotConcierge.perOrganizationStates?.find(s => s.organizationID === currentOrganizationID)
    const fetchStatus = organizationState?.fetchStatus[fetchMiniAppsKey]
    if (fetchStatus === 'pending' || fetchStatus === 'fulfilled') {
        return undefined;
    }

    dispatch(updateFetchStatus({
        fetchKey: fetchMiniAppsKey,
        orgId: currentOrganizationID,
        status: 'pending'
    }))

    let shouldFetch = true
    let nextToken = null
    let miniApps: LyzaAppMiniApp[] = []
    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        // const user = await Auth.currentAuthenticatedUser() as CognitoUser
        const variables: ListLyzaAppMiniAppsByOrgIDQueryVariables = {
            orgID: currentOrganizationID,
            nextToken
        }
        const response: GraphQLResult<ListLyzaAppMiniAppsByOrgIDQuery> = await graphQLClient.graphql<GraphQLQuery<ListLyzaAppMiniAppsByOrgIDQuery>>({
            query: queries.listLyzaAppMiniAppsByOrgID,
            variables
        });
        nextToken = response.data?.listLyzaAppMiniAppsByOrgID?.nextToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        miniApps = miniApps.concat(response.data!!.listLyzaAppMiniAppsByOrgID!!.items!! as LyzaAppMiniApp[])
    }

    return {
        organizationID: currentOrganizationID,
        miniApps
    }
})