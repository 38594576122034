import { push } from '@lagunovsky/redux-react-router'
import { createSlice, PayloadAction, createAsyncThunk, createListenerMiddleware } from '@reduxjs/toolkit'
import { BlocklyProject, CreateBlocklyProjectInput, CreateBlocklyProjectMutation, UpdateBlocklyProjectInput, UpdateBlocklyProjectMutation, GetBlocklyProjectQuery, GetBlocklyProjectQueryVariables, DeleteBlocklyProjectInput, DeleteBlocklyProjectMutation, ListBlocklyProjectsQuery, FileCategory, GetBlocklyProjectByIDQuery, GetBlocklyProjectByIDQueryVariables, CreateBlocklyProjectMutationVariables, CreateBlocklyProjectCustomMutation, CreateBlocklyProjectCustomMutationVariables, FileType, GetBlocklyProjectsByOwnerQuery, GetBlocklyProjectsByOwnerQueryVariables, ConvertTfjsToTfLiteMutation, ConvertTfjsToTfLiteMutationVariables, LyzaAppMiniApp, UpdateBlocklyProjectCustomMutation, UpdateBlocklyProjectCustomMutationVariables, UpdateBlocklyProjectMutationVariables, CopyBlocklyProjectMutationVariables, CopyBlocklyProjectMutation } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from 'src/globalUtils/graphql/queries';
import * as mutations from 'src/globalUtils/graphql/mutations';
import { RootState, AppDispatch, store } from '../store'
import { downloadBlocklyProjectFileFromLocalOrS3, S3FileDownloadResult, S3FileDownloadErrorType, uploadBlocklyProjectFileToS3 } from '../../fileStorage/s3Util'
import * as ProjectFiles from './projectFiles'
import { DateTime } from 'luxon';
import { enqueueErrorSnackbarMessage, enqueueSuccessSnackbarMessage } from '../notifications/snackbar-notification-util';
import { paths } from 'src/routes/paths';
import { ReducerStatus, axiosClientWithLongTimeout, graphQLClient } from '../util';
import { routeReplaceOrPushPath } from '../router/routerCustomSlice';
import { fetchAuthSession } from 'aws-amplify/auth';
import { getCustomAPIBaseURL } from 'src/config-global';
import awsmobile from 'src/aws-exports';
// import { DownloadStatus } from 'src/fileStorage/fileManager';
import { WritableDraft } from 'immer/dist/internal';

export interface BlocklyProjectFileWrapper {
    name: string,
    category: FileCategory,
    usedInProject: boolean | undefined, // updated by application / blockly workspace once its loaded
    // downloadStatus: DownloadStatus,
    lastUpdatedTimestamp: string,
    identityId: string
}

type BlocklyWorkspace = {
    id: string,
    workspaceJson: object,
    projectName: string,
    updatedAt: string,
    hasUnsavedChanges: boolean,
    files: BlocklyProjectFileWrapper[],
    machineLearningProjectIDs: string[],
    genAIAssistantIDs: string[],
};

interface BlocklyProjectsState {
    my_projects: BlocklyProject[]; // includes personal, and mini app projects
    fetchStatusByKey: Record<string, ReducerStatus>;
    currentWorkspace: BlocklyWorkspace | null;
}

const initialState: BlocklyProjectsState = {
    currentWorkspace: null,
    my_projects: [],
    fetchStatusByKey: {}
}

// TODO check out this
// async function postRequest(): Promise<{}> {
//     const currentSession = await fetchAuthSession();
//     const token = currentSession.tokens?.accessToken?.toString();
//     console.log(token);

//     const response = await fetch(getCustomAPIBaseURL(), {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`,
//         },
//     });

//     console.log(response);
//     if (!response.ok) {
//         throw new Error('Network response was not ok');
//     }

//     return response.json();
// };

export const fetchBlocklyProjectsKey = (input: FetchBlocklyProjectsInput) => `fetchBlocklyProjectsKey-${JSON.stringify(input.fetchType)}`
export enum FetchBlocklyProjectType {
    PERSONAL,
    MINI_APP
}

export type FetchPersonalBlocklyProjectsInput = {
    fetchType: FetchBlocklyProjectType.PERSONAL;
}
export type FetchMiniAppBlocklyProjectsInput = {
    fetchType: FetchBlocklyProjectType.MINI_APP;
    miniAppToFetchCodeLabProject?: LyzaAppMiniApp;
}
export type FetchBlocklyProjectsInput = FetchPersonalBlocklyProjectsInput | FetchMiniAppBlocklyProjectsInput
export const fetchProjects = createAsyncThunk<
    BlocklyProject[] | undefined, // output
    FetchBlocklyProjectsInput, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('blocklyProjects/fetchProjects', async (input, { dispatch, getState }) => {
    // skip if is fetching
    const fetchStatus = getState().blocklyProjects.fetchStatusByKey[fetchBlocklyProjectsKey(input)]
    if (fetchStatus === 'pending' || fetchStatus === 'fulfilled') {
        return undefined
    }

    await dispatch(updateFetchStatus({ fetchKey: fetchBlocklyProjectsKey(input), status: 'pending' }))

    const type = input.fetchType
    switch (type) {
        case FetchBlocklyProjectType.MINI_APP:
            if (input.miniAppToFetchCodeLabProject == null) {
                return []
            }
            const variables: GetBlocklyProjectByIDQueryVariables = {
                id: input.miniAppToFetchCodeLabProject.codelabProjectID
            }
            // const user = await Auth.currentAuthenticatedUser() as CognitoUser
            const response: GraphQLResult<GetBlocklyProjectByIDQuery> = await graphQLClient.graphql<GraphQLQuery<GetBlocklyProjectByIDQuery>>({
                query: queries.getBlocklyProjectByID,
                variables
            });
            if (response.errors != null) {
                throw new Error(`Failed to fetch Mini App Codelab project${response.errors}`)
            }
            return response.data.getBlocklyProjectByID ? [response.data.getBlocklyProjectByID] : []
        case FetchBlocklyProjectType.PERSONAL:
            let shouldFetch = true
            let nextToken = null
            let projects: BlocklyProject[] = []

            /* eslint-disable no-await-in-loop */
            while (shouldFetch) {
                const variables: GetBlocklyProjectsByOwnerQueryVariables = {
                    nextToken,
                    owner: getState().authentication.loggedInUser!!.userName,
                }
                // const user = await Auth.currentAuthenticatedUser() as CognitoUser
                const response: GraphQLResult<GetBlocklyProjectsByOwnerQuery> = await graphQLClient.graphql<GraphQLQuery<GetBlocklyProjectsByOwnerQuery>>({
                    query: queries.getBlocklyProjectsByOwner,
                    variables
                });
                nextToken = response.data?.getBlocklyProjectsByOwner?.nextToken
                shouldFetch = nextToken !== null && nextToken !== undefined
                projects = projects.concat(response.data!!.getBlocklyProjectsByOwner!!.items!! as BlocklyProject[])
            }
            /* eslint-enable no-await-in-loop */

            // sort by update timestamp desc
            return (projects.sort((left, right) => right!!.updatedAt!!.localeCompare(left!!.updatedAt!!)) ?? []) as BlocklyProject[]
        default:
            const exhaustiveCheck: never = type;
            throw new Error(`Unhandled case: ${exhaustiveCheck}`);
    }
})

export type UploadFileToLocalAndS3Props = {
    file: File,
    category: FileCategory,
    projectID: string,
    lastUpdatedTimestamp: string
}

export const uploadFileToLocalAndS3 = createAsyncThunk<
    void,
    UploadFileToLocalAndS3Props, // input argument - projectName
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('blocklyProjects/uploadFileToLocalAndS3', async ({ file, category, projectID, lastUpdatedTimestamp }: UploadFileToLocalAndS3Props, { dispatch, getState }) => {
    const identityId = getState().authentication.loggedInUser!!.cognitoIdentityID ?? ''
    console.log('called uploadFileToLocalAndS3')

    uploadBlocklyProjectFileToS3({
        identityId,
        lastUpdatedTimestamp,
        validatedFile: file,
        fileCategory: category,
        projectID,
        completeCallback: () => {
            dispatch(addFileLocally({
                file,
                category,
                lastUpdatedTimestamp,
                identityId
            }))
        },
        errorCallback: () => {
            console.error("failed to upload file to local and s3")
            // TODO handle error
        }
    })
})

export type CreateNewProjectProps = {
    projectName: string,
    projectImage?: File
}

export type CreateNewProjectOutput = {
    workspace: BlocklyWorkspace;
    project: BlocklyProject
}
export const createNewProject = createAsyncThunk<
    CreateNewProjectOutput, // output
    CreateNewProjectProps, // input argument - projectName
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('blocklyProjects/createNewProject', async ({ projectName, projectImage }: CreateNewProjectProps, { dispatch, getState }) => {
    const lastUpdatedTimestamp = DateTime.now().toMillis().toString()
    const input: CreateBlocklyProjectInput = {
        workspaceJson: JSON.stringify({
            blocks: {
                languageVersion: 0,
                blocks: [
                    {
                        type: 'start_block',
                        id: 'c_/rz-6A/4g4p$hL6_T9',
                        x: 100,
                        y: 100,
                        deletable: false
                    }
                ]
            }
        } as object),
        projectName,
        identityID: getState().authentication.loggedInUser!!.cognitoIdentityID ?? '',
        files: projectImage ? [{ name: projectImage.name, category: FileCategory.PROJECT_PROFILE_IMAGE, lastUpdatedTimestamp, identityId: getState().authentication.loggedInUser!!.cognitoIdentityID }] : [],
        machineLearningProjectIDs: [],
        genAIAssistantIDs: [],
    }

    const response = await graphQLClient.graphql<GraphQLQuery<CreateBlocklyProjectMutation>>({
        query: mutations.createBlocklyProject,
        variables: {
            input
        }
    });
    if (response.data.createBlocklyProject == null || response.errors) {
        throw new Error("Failed to create blockly project");
    }

    const blocklyProject = response.data.createBlocklyProject
    if (projectImage !== undefined) {
        dispatch(uploadFileToLocalAndS3({ file: projectImage, category: FileCategory.PROJECT_PROFILE_IMAGE, projectID: blocklyProject.id, lastUpdatedTimestamp }))
    }

    return {
        workspace: {
            id: blocklyProject.id,
            workspaceJson: JSON.parse(blocklyProject.workspaceJson) as object,
            projectName: blocklyProject.projectName,
            updatedAt: blocklyProject.updatedAt,
            hasUnsavedChanges: false,
            files: input.files.map(f => { return { name: f.name, category: f.category, usedInProject: true, lastUpdatedTimestamp, identityId: f.identityId ?? getState().authentication.loggedInUser!!.cognitoIdentityID ?? '' } }),
            machineLearningProjectIDs: input.machineLearningProjectIDs ?? [],
            genAIAssistantIDs: input.genAIAssistantIDs ?? []
        },
        project: blocklyProject
    };
})

export interface UpdateProjectProps {
    projectName: string,
    projectImage?: File // undefined means no change
}

export const updateProject = createAsyncThunk<
    {
        updatedProject: BlocklyProject,
        updatedCurrentWorkspace: BlocklyWorkspace
    },
    UpdateProjectProps, // input argument - projectName
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('blocklyProjects/updateProject', async ({ projectName, projectImage }, { dispatch, getState }) => {
    let input: UpdateBlocklyProjectCustomMutationVariables = {
        id: getState().blocklyProjects.currentWorkspace!!.id,
        projectName
    }

    let files = getState().blocklyProjects.currentWorkspace!!.files
    const existingProjectImageName = files.find(f => f.category === FileCategory.PROJECT_PROFILE_IMAGE)?.name
    const shouldUpdateProjectImage = projectImage !== undefined
    const lastUpdatedTimestamp = DateTime.now().toMillis().toString()
    if (shouldUpdateProjectImage) {
        files = files.filter(f => f.category !== FileCategory.PROJECT_PROFILE_IMAGE)
        files.push({
            name: projectImage.name,
            category: FileCategory.PROJECT_PROFILE_IMAGE,
            usedInProject: true,
            // downloadStatus: DownloadStatus.COMPLETED,
            lastUpdatedTimestamp,
            identityId: getState().authentication.loggedInUser!!.cognitoIdentityID ?? ''
        })
        input = {
            ...input,
            files: files.map(f => { return { name: f.name, category: f.category, lastUpdatedTimestamp: f.lastUpdatedTimestamp, identityId: f.identityId } })
        }
    }

    const project = await graphQLClient.graphql<GraphQLQuery<UpdateBlocklyProjectCustomMutation>>({
        query: mutations.updateBlocklyProjectCustom,
        variables: input
    }).then(d => d.data!!.updateBlocklyProjectCustom!!)

    if (shouldUpdateProjectImage) {
        if (existingProjectImageName !== undefined && existingProjectImageName !== projectImage.name) {
            // TODO update
            dispatch(removeFileFromWorkspaceAndS3({
                fileName: existingProjectImageName,
                fileCategory: FileCategory.PROJECT_PROFILE_IMAGE
            }))
        }
        // TODO update
        dispatch(uploadFileToLocalAndS3({
            projectID: getState().blocklyProjects.currentWorkspace!!.id,
            file: projectImage,
            category: FileCategory.PROJECT_PROFILE_IMAGE,
            lastUpdatedTimestamp
        }))
    }
    return {
        updatedProject: project,
        updatedCurrentWorkspace: {
            ...getState().blocklyProjects.currentWorkspace!!,
            projectName,
            updatedAt: project.updatedAt,
            files
        }
    };
})

// TODO optimize - do not update when there's no new changes
export const saveProjectWorkspace = createAsyncThunk<
    {
        updatedCurrentWorkspace: BlocklyWorkspace,
        updatedBlocklyProject: BlocklyProject
    } | undefined, // output
    void, // input argument - onSuccess callback function
    {
        state: RootState
    }
>('blocklyProjects/saveProjectWorkspace', async (_, { getState }) => {
    const currentWorkspace = getState().blocklyProjects.currentWorkspace!!
    // don't save if there's no changes
    if (!currentWorkspace.hasUnsavedChanges) {
        return undefined
    }
    const input: UpdateBlocklyProjectCustomMutationVariables = {
        id: currentWorkspace.id,
        workspaceJson: JSON.stringify(currentWorkspace.workspaceJson),
        machineLearningProjectIDs: currentWorkspace.machineLearningProjectIDs ?? [],
        genAIAssistantIDs: currentWorkspace.genAIAssistantIDs ?? []
    }
    const response = await graphQLClient.graphql<GraphQLQuery<UpdateBlocklyProjectCustomMutation>>({
        query: mutations.updateBlocklyProjectCustom,
        variables: input
    });
    if (response.data.updateBlocklyProjectCustom == null || response.errors) {
        throw new Error("Failed to save blockly workspace")
    }
    const data: BlocklyProject = response.data.updateBlocklyProjectCustom
    return {
        updatedCurrentWorkspace: {
            ...currentWorkspace,
            updatedAt: data!!.updatedAt,
            hasUnsavedChanges: false
        },
        updatedBlocklyProject: data
    }
})

// TODO update
// the file has already been uploaded to S3, this action is just to update the blocklyproject metadata
export const syncFilesToServerBlocklyProject = createAsyncThunk<
    void, // output - files
    void, // input argument - file name
    {
        state: RootState
    }
>('blocklyProjects/syncFilesToServerBlocklyProject', async (_, { getState }) => {
    const currentWorkspace = getState().blocklyProjects.currentWorkspace!!
    const input: UpdateBlocklyProjectCustomMutationVariables = {
        id: currentWorkspace.id,
        files: currentWorkspace.files.map(f => { return { name: f.name, category: f.category, lastUpdatedTimestamp: f.lastUpdatedTimestamp, identityId: f.identityId } })
    }
    await graphQLClient.graphql<GraphQLQuery<UpdateBlocklyProjectCustomMutation>>({
        query: mutations.updateBlocklyProjectCustom,
        variables: input
    });
})

export type RemoveFileFromWorkspaceAndS3Props = {
    fileName: string,
    fileCategory: FileCategory
}

// TODO update
export const removeFileFromWorkspaceAndS3 = createAsyncThunk<
    BlocklyProjectFileWrapper[], // output - files
    RemoveFileFromWorkspaceAndS3Props, // input argument - file name
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('blocklyProjects/removeFileFromWorkspaceAndS3', async ({ fileName, fileCategory }: RemoveFileFromWorkspaceAndS3Props, { getState }) => {
    const projectID = getState().blocklyProjects.currentWorkspace!!.id
    const filesAfterRemoval = getState().blocklyProjects.currentWorkspace!!.files.filter(f => !(f.name === fileName && f.category === fileCategory))

    // update workspace remotely
    const input: UpdateBlocklyProjectCustomMutationVariables = {
        id: projectID,
        files: filesAfterRemoval.map(f => { return { name: f.name, category: f.category, lastUpdatedTimestamp: f.lastUpdatedTimestamp } })
    }
    await graphQLClient.graphql<GraphQLQuery<UpdateBlocklyProjectCustomMutation>>({
        query: mutations.updateBlocklyProjectCustom,
        variables: input
    });

    // don't remove from S3 so that coped/cloned project won't fail. - easier solution. Also, assuming most users don't delete files..
    // await removeBlocklyProjectFileFromS3(fileName, projectID, fileCategory)

    return filesAfterRemoval
})

// const getOnFileDownloadError = (dispatch: AppDispatch, fileName: string, fileCategory: FileCategory) => {
//     return (result: S3FileDownloadResult) => {
//         if (result.errorType === S3FileDownloadErrorType.FILE_DOES_NOT_EXIST) {
//             dispatch(removeFileFromWorkspaceAndS3({ fileName, fileCategory }))
//         }
//         dispatch(updateFileDownloadStatus({ fileName: result.file!!.name, fileCategory: result.category, downloadStatus: DownloadStatus.FAILED }))
//     }
// }

// const getOnFileDownloadSuccess = (dispatch: AppDispatch) => {
//     return (result: S3FileDownloadResult) => {
//         dispatch(updateFileDownloadStatus({ fileName: result.file!!.name, fileCategory: result.category, downloadStatus: DownloadStatus.COMPLETED }))
//     }
// }

export type LoadFileFromLocalOrS3Props = {
    fileName: string,
    category: FileCategory
}


// TODO update - load all required files at the beginning of loading project.
// then load other required files/names as we go
export const loadRemainingRequiredFileFromS3 = createAsyncThunk<
    void, // output - files
    FileCategory, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('blocklyProjects/loadRemainingRequiredFileFromS3', async (fileCategory, { dispatch, getState }) => {
    try {
        const projectID = getState().blocklyProjects.currentWorkspace!!.id
        // TODO don't have to download all images at once
        const filesToDownload: BlocklyProjectFileWrapper[] = getState().blocklyProjects.currentWorkspace!!.files
            .filter(f =>
                // f.downloadStatus === DownloadStatus.NOT_STARTED 
                // && 
                fileCategory === f.category)
        await Promise.all(filesToDownload.map(f => {
            const fileType: FileType = {
                __typename: "FileType",
                lastUpdatedTimestamp: f.lastUpdatedTimestamp,
                name: f.name,
                category: f.category,
                identityId: f.identityId,
            }
            return downloadBlocklyProjectFileFromLocalOrS3(fileType, projectID)
        }))
    } catch (e) {
        console.error(e)
        throw e
    }
})

const loadBlocklyProjectById = async (projectID: string) => {
    const variables: GetBlocklyProjectByIDQueryVariables = {
        id: projectID
    }
    return graphQLClient.graphql<GraphQLQuery<GetBlocklyProjectByIDQuery>>({
        query: queries.getBlocklyProjectByID,
        variables
    });
}

const mapBlocklyFileToWorkspaceFile = (file: FileType, userIdentityId: string) => {
    return {
        name: file.name,
        category: file.category,
        usedInProject: undefined,
        // downloadStatus: DownloadStatus.NOT_STARTED,
        lastUpdatedTimestamp: file.lastUpdatedTimestamp ?? DateTime.now().toMillis().toString(),
        identityId: file.identityId ?? userIdentityId
    }
}

export type SelectBlocklyProjectOutputPayload = {
    projectID: string,
    updateWorkspace: BlocklyWorkspace | undefined,
    blocklyProjectLoadedFromServer: BlocklyProject | undefined,
}
export const selectBlocklyProject = createAsyncThunk<
    SelectBlocklyProjectOutputPayload, // output
    {
        projectID: string,
    }, // input argument - blocklyProject id
    {
        dispatch: AppDispatch,
        state: RootState,
    }
>('blocklyProjects/selectBlocklyProject', async ({ projectID }, { dispatch, getState }) => {
    // don't load if already loaded
    if (getState().blocklyProjects.currentWorkspace?.id === projectID) {
        const updateWorkspace = getState().blocklyProjects.currentWorkspace ?? undefined
        return {
            projectID,
            updateWorkspace,
            blocklyProjectLoadedFromServer: undefined,
        }
    }

    // use existing from project list
    const existing = getState().blocklyProjects.my_projects?.find(p => p.id === projectID)
    if (existing) {
        return {
            projectID,
            updateWorkspace: {
                id: existing.id,
                workspaceJson: JSON.parse(existing.workspaceJson) as object,
                projectName: existing.projectName,
                updatedAt: existing.updatedAt,
                hasUnsavedChanges: false,
                files: existing.files.map(f => mapBlocklyFileToWorkspaceFile(f, getState().authentication.loggedInUser!!.cognitoIdentityID ?? '')),
                machineLearningProjectIDs: existing.machineLearningProjectIDs ?? [],
                genAIAssistantIDs: existing.genAIAssistantIDs ?? [],
            },
            blocklyProjectLoadedFromServer: undefined,
        }
    }

    const response = await loadBlocklyProjectById(projectID)
    const blocklyProject = response.data!!.getBlocklyProjectByID!!
    return {
        projectID,
        updateWorkspace: {
            id: blocklyProject.id,
            workspaceJson: JSON.parse(blocklyProject.workspaceJson) as object,
            projectName: blocklyProject.projectName,
            updatedAt: blocklyProject.updatedAt,
            hasUnsavedChanges: false,
            files: blocklyProject.files.map(f => mapBlocklyFileToWorkspaceFile(f, getState().authentication.loggedInUser!!.cognitoIdentityID ?? '')),
            machineLearningProjectIDs: blocklyProject.machineLearningProjectIDs ?? [],
            genAIAssistantIDs: blocklyProject.genAIAssistantIDs ?? []
        },
        blocklyProjectLoadedFromServer: blocklyProject as BlocklyProject,
    }
})

export const copyBlocklyProjectsByID = createAsyncThunk<
    BlocklyProject, // the new project...
    {
        projectIDToCopy: string, // input argument - projectID
        projectName: string,
        groupID: string | undefined, //
    }, // input
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('blocklyProjects/copyBlocklyProjectsByID', async ({ projectIDToCopy, projectName }, { dispatch, getState }) => {
    const saveProjectResult = await store.dispatch(saveProjectWorkspace())

    if (saveProjectResult.meta.requestStatus !== 'fulfilled') {
        throw new Error("Failed to copy because save existing project didn't work")
    }
    const input: CopyBlocklyProjectMutationVariables = {
        existingProjectID: projectIDToCopy,
        newProjectName: projectName
    }
    const response = await graphQLClient.graphql<GraphQLQuery<CopyBlocklyProjectMutation>>({
        query: mutations.copyBlocklyProject,
        variables: {
            ...input
        }
    });

    const result = response.data.copyBlocklyProject
    if ((response.errors?.length ?? 0) > 0 || !result) {
        throw new Error(`${JSON.stringify(response.errors)}`)
    }
    return result
})

// TODO convert to batch mutation
// See https://docs.amplify.aws/javascript/build-a-backend/graphqlapi/custom-business-logic/
export const deleteBlocklyProjectsByID = createAsyncThunk<
    { deletedProjectId: string } | undefined, // output
    string, // input argument - projectIDs
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('blocklyProjects/deleteBlocklyProjectsByID', async (projectIDToDelete, { dispatch, getState }) => {
    const input: DeleteBlocklyProjectInput = {
        id: projectIDToDelete
    }
    const response = await graphQLClient.graphql<GraphQLQuery<DeleteBlocklyProjectMutation>>({
        query: mutations.deleteBlocklyProject,
        variables: {
            input
        }
    });
    if (response.data.deleteBlocklyProject?.id === projectIDToDelete) {
        return {
            deletedProjectId: projectIDToDelete
        }
    }
    return undefined
})

export type AddFileLocallyPayload = {
    file: File,
    category: FileCategory,
    lastUpdatedTimestamp: string,
    identityId: string
}

export type RemoveFileLocallyPayload = {
    fileName: string,
    category: FileCategory
}

function saveCodelabProjectHelper(state: WritableDraft<BlocklyProjectsState>, project: BlocklyProject) {
    state.my_projects = [project, ...state.my_projects?.filter(p => p.id !== project.id) ?? []].sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
}

function updateFetchStatusHelper({ state, fetchKey, status }: { state: WritableDraft<BlocklyProjectsState>, fetchKey: string; status: ReducerStatus }) {
    state.fetchStatusByKey[fetchKey] = status
}

export const blocklyProjectsSlice = createSlice({
    name: 'blocklyProjects',
    initialState,
    reducers: {
        updateFetchStatus: (state, action: PayloadAction<{ fetchKey: string; status: ReducerStatus }>) => {
            updateFetchStatusHelper({ state, status: action.payload.status, fetchKey: action.payload.fetchKey })
        },
        saveCodelabProject: (state, action: PayloadAction<{ project: BlocklyProject; }>) => {
            saveCodelabProjectHelper(state, action.payload.project)
        },
        deleteCodelabProject: (state, action: PayloadAction<{ projectIdToDelete: string }>) => {
            state.my_projects = state.my_projects?.filter(p => p.id !== action.payload.projectIdToDelete)
        },
        updateBlocklyAssociatedMachineLearningProjects: (state, action: PayloadAction<{ mlProjectIDToAdd: string | undefined, mlProjectIDToRemove: string | undefined }>) => {
            const updated = [
                ...state.currentWorkspace!!.machineLearningProjectIDs.filter(x => x !== action.payload.mlProjectIDToRemove),
                action.payload.mlProjectIDToAdd
            ]
                .filter(x => x !== undefined) as string[];
            state.currentWorkspace!!.machineLearningProjectIDs = Array.from(new Set(updated));
            state.currentWorkspace!!.hasUnsavedChanges = true
        },
        updateBlocklyAssociatedGenAIAssistantIds: (state, action: PayloadAction<{ idToAdd: string | undefined, idToRemove: string | undefined }>) => {
            const updated = [
                ...state.currentWorkspace!!.genAIAssistantIDs.filter(x => x !== action.payload.idToRemove),
                action.payload.idToAdd
            ]
                .filter(x => x !== undefined) as string[]
            state.currentWorkspace!!.genAIAssistantIDs = Array.from(new Set(updated));

            state.currentWorkspace!!.hasUnsavedChanges = true
        },
        // TODO check
        // updateFileDownloadStatus: (state, action: PayloadAction<{ fileName: string, fileCategory: FileCategory, downloadStatus: DownloadStatus }>) => {
        //     const f = state.currentWorkspace!!.files.find(f => f.name === action.payload.fileName && f.category === action.payload.fileCategory)
        //     if (f) f.downloadStatus = action.payload.downloadStatus
        // },
        // TODO check
        tryMarkFileAsUsedInProject: (state, action: PayloadAction<string>) => {
            const file = state.currentWorkspace!!.files.find(f => f.name === action.payload)
            if (file) {
                file.usedInProject = true
            }
        },
        updateCurrentProjectWorkspaceJson: (state, action: PayloadAction<object>) => {
            if (JSON.stringify(state.currentWorkspace!!.workspaceJson) !== JSON.stringify(action.payload)) {
                state.currentWorkspace!!.hasUnsavedChanges = true
                state.currentWorkspace!!.workspaceJson = action.payload
            }
        },
        addFileLocally: (state, action: PayloadAction<AddFileLocallyPayload>) => {
            const fileToAdd = action.payload
            const files = state.currentWorkspace!!.files.filter(f => !(f.name === fileToAdd.file.name && f.category === fileToAdd.category))
            // ProjectFiles.addProjectFile(fileToAdd.file, fileToAdd.category)
            files.push({
                name: fileToAdd.file.name,
                category: fileToAdd.category,
                usedInProject: false,
                // downloadStatus: DownloadStatus.COMPLETED,
                lastUpdatedTimestamp: fileToAdd.lastUpdatedTimestamp,
                identityId: fileToAdd.identityId
            })
            state.currentWorkspace!!.files = files
        },
        removeFileLocally: (state, action: PayloadAction<RemoveFileLocallyPayload>) => {
            const fileToRemove = action.payload
            // ProjectFiles.removeProjectFile(fileToRemove.fileName, fileToRemove.category)
            state.currentWorkspace!!.files = state.currentWorkspace!!.files.filter(f => !(f.name === fileToRemove.fileName && f.category === fileToRemove.category))
        },
    },
    extraReducers(builder) {
        // fetch project
        builder.addCase(fetchProjects.pending, (state, action) => {
        })
        builder.addCase(fetchProjects.fulfilled, (state, action) => {
            if (action.payload == null) {
                // skipped
                return
            }
            const fetchedProjectIdsSet = new Set(action.payload.map(p => p.id))
            state.my_projects = [...action.payload, ...state.my_projects.filter(p => !fetchedProjectIdsSet.has(p.id))].sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
            updateFetchStatusHelper({ state, fetchKey: fetchBlocklyProjectsKey(action.meta.arg), status: 'fulfilled' })
        })
        builder.addCase(fetchProjects.rejected, (state, action) => {
            updateFetchStatusHelper({ state, fetchKey: fetchBlocklyProjectsKey(action.meta.arg), status: 'rejected' })
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to load your CodeLab projects')
        })

        // createNewProject
        builder.addCase(createNewProject.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(createNewProject.fulfilled, (state, action) => {
            state.currentWorkspace = action.payload.workspace
            saveCodelabProjectHelper(state, action.payload.project)
            // there is a listener attached to the fulfilled action
            enqueueSuccessSnackbarMessage('Successfully created CodeLab project')
        })
        builder.addCase(createNewProject.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Error creating CodeLab project')
        })

        // updateProject
        builder.addCase(updateProject.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(updateProject.fulfilled, (state, action) => {
            state.currentWorkspace = action.payload.updatedCurrentWorkspace
            saveCodelabProjectHelper(state, action.payload.updatedProject)
            enqueueSuccessSnackbarMessage('Successfully updated CodeLab project')
        })
        builder.addCase(updateProject.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Error updating CodeLab project')
        })

        // saveCurrentProject
        builder.addCase(saveProjectWorkspace.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(saveProjectWorkspace.fulfilled, (state, action) => {
            const payload = action.payload
            if (payload == null) {
                // skip
                return
            }
            state.currentWorkspace = payload.updatedCurrentWorkspace
            saveCodelabProjectHelper(state, payload.updatedBlocklyProject)
            enqueueSuccessSnackbarMessage('Successfully saved CodeLab project')
        })
        builder.addCase(saveProjectWorkspace.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Error saving CodeLab project')
        })

        // selectBlocklyProject
        builder.addCase(selectBlocklyProject.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(selectBlocklyProject.fulfilled, (state, action) => {
            // TODO add a status state for completion. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            if (action.payload.updateWorkspace) {
                state.currentWorkspace = action.payload.updateWorkspace
            }
            const { blocklyProjectLoadedFromServer } = action.payload
            if (blocklyProjectLoadedFromServer) {
                saveCodelabProjectHelper(state, blocklyProjectLoadedFromServer)
            }
        })
        builder.addCase(selectBlocklyProject.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage(`CodeLab project doesn't exist or you don't have permission to access it`)
        })

        // removeFileFromWorkspaceAndS3
        builder.addCase(removeFileFromWorkspaceAndS3.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(removeFileFromWorkspaceAndS3.fulfilled, (state, action) => {
            // TODO add a status state for completion. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            state.currentWorkspace!!.files = action.payload
            enqueueSuccessSnackbarMessage('Successfully deleted project file')
        })
        builder.addCase(removeFileFromWorkspaceAndS3.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to delete the project file')
        })

        // deleteBlocklyProjectsByID
        builder.addCase(deleteBlocklyProjectsByID.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(deleteBlocklyProjectsByID.fulfilled, (state, action) => {
            // TODO add a status state for completion. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            const payload = action.payload
            if (payload == null) {
                return
            }
            state.my_projects = state.my_projects.filter(p => p.id !== payload.deletedProjectId)
            enqueueSuccessSnackbarMessage('Successfully deleted CodeLab project')
        })
        builder.addCase(deleteBlocklyProjectsByID.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to delete CodeLab project')
        })

        // copyBlocklyProjectsByID
        builder.addCase(copyBlocklyProjectsByID.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(copyBlocklyProjectsByID.fulfilled, (state, action) => {
            // TODO add a status state for completion. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            if (action.payload) {
                saveCodelabProjectHelper(state, action.payload)
            }
            enqueueSuccessSnackbarMessage('Successfully copied the CodeLab project')
        })
        builder.addCase(copyBlocklyProjectsByID.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to copy CodeLab project')
        })
    }
})

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    actionCreator: selectBlocklyProject.fulfilled,
    effect: async (action, listenerApi) => {
        store.dispatch(routeReplaceOrPushPath(paths.codelab.project(action.payload.projectID)))
    }
})

listenerMiddleware.startListening({
    actionCreator: selectBlocklyProject.rejected,
    effect: async (action, listenerApi) => {
        store.dispatch(routeReplaceOrPushPath(paths.page404))
    }
})

export const { updateFetchStatus, updateBlocklyAssociatedMachineLearningProjects, saveCodelabProject, deleteCodelabProject, updateBlocklyAssociatedGenAIAssistantIds, updateCurrentProjectWorkspaceJson, addFileLocally, removeFileLocally, tryMarkFileAsUsedInProject } = blocklyProjectsSlice.actions

export default blocklyProjectsSlice.reducer