import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../../store"
import { CreateRobotCustomActionsMutation, CreateRobotCustomActionsMutationVariables, KnowledgeBase, RobotCustomAction } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from "aws-amplify/api"
import * as mutations from 'src/globalUtils/graphql/mutations';
import { graphQLClient } from "../../util";

export const createRobotCustomActions = createAsyncThunk<
    {
        organizationID: string,
        createdRobotCustomActions: RobotCustomAction[],
        updatedKnowledgeBase: KnowledgeBase
    }, // output
    {
        createRobotCustomActionsVariables: CreateRobotCustomActionsMutationVariables,
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/createRobotCustomActions', async ({ createRobotCustomActionsVariables }, { dispatch, getState }) => {
    const orgId = getState().authentication.currentSelectedOrganizationID!!
    const response: GraphQLResult<CreateRobotCustomActionsMutation> = await graphQLClient.graphql<GraphQLQuery<CreateRobotCustomActionsMutation>>({
        query: mutations.createRobotCustomActions,
        variables: createRobotCustomActionsVariables
    });
    if (response.errors) {
        throw new Error("Failed to create knowledge base")
    }
    return {
        organizationID: orgId,
        createdRobotCustomActions: response.data.createRobotCustomActions.createdRobotCustomActions,
        updatedKnowledgeBase: response.data.createRobotCustomActions.updatedKnowledgeBase
    }
})