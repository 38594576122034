import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../../store"
import { DeleteLyzaAppMiniAppCustomMutationVariables, DeleteLyzaAppMiniAppCustomMutation, LyzaAppMiniApp } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from "aws-amplify/api"
import * as mutations from 'src/globalUtils/graphql/mutations';
import { graphQLClient } from "../../util";
import { deleteCodelabProject } from "src/store/blocklyProjects/blocklyProjectsSlice";

export const deleteLyzaAppMiniApp = createAsyncThunk<
    {
        organizationID: string,
        deletedLyzaAppMiniApp: DeleteLyzaAppMiniAppCustomMutationVariables,
    }, // output
    {
        input: DeleteLyzaAppMiniAppCustomMutationVariables,
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/deleteLyzaAppMiniApps', async ({ input }, { dispatch, getState }) => {
    const organizationID = (getState().authentication.currentSelectedOrganizationID!!)
    const variables: DeleteLyzaAppMiniAppCustomMutationVariables = input
    const response: GraphQLResult<DeleteLyzaAppMiniAppCustomMutation> = await graphQLClient.graphql<GraphQLQuery<DeleteLyzaAppMiniAppCustomMutation>>({
        query: mutations.deleteLyzaAppMiniAppCustom,
        variables
    });
    if (response.errors || response.data.deleteLyzaAppMiniAppCustom !== true) {
        throw new Error("Failed to delete the Mini App")
    }
    await dispatch(deleteCodelabProject({
        projectIdToDelete: variables.codelabProjectID
    }))
    return {
        organizationID,
        deletedLyzaAppMiniApp: input
    }
})