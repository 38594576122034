// src/useScreenWidth.ts
import { useMediaQuery, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';

const useIsSmallScreen = () => {
  //   const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  //   useEffect(() => {
  //     const handleResize = () => {
  //       setScreenWidth(window.innerWidth);
  //     };

  //     window.addEventListener('resize', handleResize);

  //     // Cleanup event listener on component unmount
  //     return () => {
  //       window.removeEventListener('resize', handleResize);
  //     };
  //   }, []);

  //   return screenWidth < 600;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  return isSmallScreen
};

export default useIsSmallScreen;
